import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';
import { AppError } from '@vk-hr-tek/core/error';

@injectable()
export class TranslationLocaleService {
  private readonly key = 'language';

  private readonly defaultLanguage = 'ru';

  private readonly supportedLanguages = ['ru'];

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  get() {
    const lang = this.localStorage.getItem(this.key);

    if (!lang || !this.supportedLanguages.includes(lang)) {
      return this.defaultLanguage;
    }

    return lang;
  }

  save(lang: 'ru') {
    if (!this.supportedLanguages.includes(lang)) {
      throw new AppError('client', {
        code: 400,
        message: 'Нет поддержки данного языка',
      });
    }

    this.localStorage.setItem(this.key, lang);
  }
}

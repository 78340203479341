import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { User } from '@vk-hr-tek/core/logger';
import { Socket } from '@vk-hr-tek/core/websocket';
import { TokenService } from '@vk-hr-tek/core/http';
import { AppError } from '@vk-hr-tek/core/error';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';

import { AuthLoginService, AuthRouterService } from '../../../services';
import { LoginInitDto } from '../../../dto';
import {
  AuthState,
  startLoading,
  completeLoading,
  resetState,
  setError,
  ThunkExtra,
} from '../../auth.state';
import { WAITING_TIME_AFTER_LOGIN } from '../../../auth.constants';

interface LogoutPayload {
  skipLogout: boolean;
}

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const loginInit = createAsyncThunk<
  void,
  {
    values: LoginInitDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/loginInit',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(AuthLoginService).loginInit(values);

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const login = createAsyncThunk<
  AuthResponse,
  {
    values: { code: string };
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/login',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { password, phone } = state.values;
      const { inviteCode } = state;

      const result = await inject(AuthLoginService).login({
        password: password || undefined,
        phone: phone || undefined,
        code: values.code,
        inviteCode: inviteCode || undefined,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(WAITING_TIME_AFTER_LOGIN);

      actions.resolve(null);

      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const logout = createAsyncThunk<
  void,
  LogoutPayload | undefined,
  ThunkExtra
>('auth/logout', async (payload, { extra: { inject } }) => {
  if (!payload?.skipLogout) {
    inject(AuthLoginService).logout();
  }

  inject<TokenService>(TokenService).delete();
  const socket = inject(Socket);
  inject<User>(User).save(null);
  inject(AuthRouterService).redirectToLogin();

  socket.resetWs();
});

export const loginReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(loginInit.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.values.phone;
    state.values.password = action.meta.arg.values.password;
  });
  builder.addCase(loginInit.fulfilled, (state) => {
    completeLoading(state);
    state.stage = 'finish';
  });
  builder.addCase(loginInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(login.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(login.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(login.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};

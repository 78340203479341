import { inject, injectable } from 'inversify';

import { TokenService } from '../http';
import { Notification } from '../notifications/gen/notifications';
import { Logger } from '../logger';

const TIMEOUT = 60000;
const SUCCESS_CLOSE_CODE = 1000;
const ATTEMPTS = 5;
@injectable()
export class Socket {
  ws: null | WebSocket;

  private reconnectAttempt: number;

  private listeners: Record<string, ((payload: Notification) => void)[]>;

  private token: TokenService;

  constructor(
    @inject(TokenService) token: TokenService,
    private readonly logger: Logger,
  ) {
    this.listeners = {};
    this.token = token;
    this.reconnectAttempt = 0;
  }

  /** Выполнить подключение к Websocket серверу
   * Осуществляется подписка на события:
   * onopen - срабатывает, когда соединение с Websocket установлено и становится досупен двусторонний обмен сообщениями
   * onmessage - срабатывает каждый раз, когда от Websocket получено сообщение, и происходит рассылка всем подписчикам
   * onerror - срабатывает, когда на Websocket произошла ошибка, логирует сообщение об ошибке будет залогировано
   * onclose - срабатывает, когда Websocket соединение переходит в статус "closed", может выполнить переподключение
   *  */
  connect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }

    this.ws = new WebSocket(
      `${window.REACT_APP_VKHRTEK_WS_API_URL}/ws/connect`,
    );

    this.ws.onopen = () => {
      const token = this.token.get();

      if (this.ws && token) {
        this.ws.send(token);
      }
    };

    this.ws.onmessage = (message) => {
      const { type, payload } = JSON.parse(message.data);

      if (this.listeners[type]) {
        this.listeners[type].forEach((listener) => listener(payload));
      }
    };

    this.ws.onerror = (error) => {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'ws',
          http_method: 'WS',
          http_url: `${window.REACT_APP_VKHRTEK_WS_API_URL}/ws/connect`,
        },
      });
    };

    this.ws.onclose = (message) => {
      if (
        message.code !== SUCCESS_CLOSE_CODE &&
        this.reconnectAttempt <= ATTEMPTS
      ) {
        this.reconnectAttempt++;

        setTimeout(() => {
          this.connect();
        }, 2 ** this.reconnectAttempt * TIMEOUT); //eslint-disable-line no-magic-numbers
      } else {
        this.resetWs();
      }
    };
  }

  /** Добавить подписчика, который будет слушать сообщения от Websocket
   * @param {string} type Тип сообщения от Websocket, на данный момент доступен единственный тип "notification"
   * @param callback Подписчик на сообщения от Websocket
   * @return {void}
   * */
  on(type: string, callback: (payload: Notification) => void) {
    if (this.listeners[type]) {
      this.listeners[type].push(callback);
    } else {
      this.listeners[type] = [callback];
    }
  }

  /** Отписать всех подписчиков Websocket
   * @returns {void}
   * */
  private unsubscribeAll() {
    this.listeners = {};
  }

  /** Закрыть Websocket соединение
   * @returns {void}
   * */
  resetWs() {
    this.ws?.close();
    this.ws = null;
    this.unsubscribeAll();
  }
}

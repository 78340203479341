import React from 'react';

import {
  useField,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { ValidateType } from '@vk-hr-tek/core/validation';

import { SelectInput as Input, OptionBadge } from '../../input';

const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: (value: string | number | undefined) => void,
  ) =>
  (value: string | number | undefined) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

const SelectInput = ({
  label,
  tooltip,
  name,
  onChange,
  placeholder,
  showError = true,
  required = false,
  disabled = false,
  autocomplete = false,
  clearable = false,
  loading = false,
  items,
  defaultValue,
  renderOption,
  testId,
  ...rest
}: {
  defaultValue?: string | string[] | number | number[];
  validate?: ValidateType[] | ValidateType;
  items: {
    label: string;
    value: string | number;
    badge?: OptionBadge;
    group?: string;
  }[];
} & FieldProps<
  string | string[] | number | number[] | undefined,
  FieldRenderProps<string | string[] | number | number[] | undefined>
>) => {
  const { input, meta } = useField(name, {
    initialValue: defaultValue,
    ...rest,
  });

  return (
    <Input
      {...input}
      label={label}
      onChange={handleChange(input, onChange)}
      placeholder={placeholder}
      value={input.value}
      items={items}
      required={required}
      disabled={disabled}
      autocomplete={autocomplete}
      clearable={clearable}
      loading={loading}
      error={meta.touched && !!meta.error}
      helperText={showError && meta.touched && meta.error}
      tooltip={tooltip}
      renderOption={renderOption}
      testId={testId}
    />
  );
};

export default SelectInput;

import { injectable } from 'inversify';

import {
  CreateEventItem,
  CreateEventEmployee,
  EventTypeGroup,
} from '@app/gen/events';

import { EventTypeItemWithGroup } from '../types/EventTypeItemWithGroup';

@injectable()
export class AbsenceEventCreationMapper {
  findFormRoleFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValuesObj: Record<string, any>,
    getFullFieldName?: boolean,
  ) {
    return Object.keys(formValuesObj)
      .filter((roleId) => roleId)
      .map((key) => {
        const regexpResultArray = key.match(/^roles_([0-9a-zA-Z-]+)$/);

        if (regexpResultArray) {
          return getFullFieldName ? regexpResultArray[0] : regexpResultArray[1];
        }

        return null;
      });
  }

  gatherAssignedRoles(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: { role_id: string; group_id: string }[], roleId) => {
        return !roleId
          ? acc
          : [
              ...acc,
              { role_id: roleId, group_id: formValuesObj[`roles_${roleId}`] },
            ];
      },
      [],
    );
  }

  processCreateEventItem(items: CreateEventItem[]) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
      employees: this.processEmployees(item.employees),
    }));
  }

  processEmployees(employees: CreateEventEmployee[]) {
    return employees
      .map((employee) => {
        const eventTypes = employee.event_types;

        const eventTypeItems = eventTypes
          .filter(
            (event): event is EventTypeItemWithGroup => event.type === 'item',
          )
          .sort((a, b) => {
            return (a.name || '').toLowerCase() > (b.name || '').toLowerCase()
              ? 1
              : -1;
          });

        const eventTypeGroups = eventTypes.filter(
          (event): event is EventTypeGroup => event.type === 'group',
        );

        eventTypeGroups.forEach((event) => {
          if (event.items) {
            event.items.forEach((item) =>
              eventTypeItems.unshift({
                ...item,
                group: event.label,
              }),
            );
          }
        });

        return {
          ...employee,
          personnelNumber: employee.personnel_number,
          eventTypes: eventTypeItems,
        };
      })
      .sort((a, b) =>
        (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
      );
  }
}

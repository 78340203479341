import React, { ReactNode } from 'react';

import {
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
  useField,
} from 'react-final-form';

import { ValidateType } from '@vk-hr-tek/core/validation';

import { AutocompleteInput as Input, Option, Filter } from '../../input';

const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: ((value: string | undefined) => void) | undefined,
  ) =>
  (value: string | undefined) => {
    input.onChange(value);

    onChange?.(value);
  };

interface AutocompleteInputProps {
  label: string;
  name: string;
  onChange?: (value: string | undefined) => void;
  placeholder?: string;
  required?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  limit?: number;
  loadItems: (filter: Filter) => Promise<Option[]>;
  renderOption?: (option: Option) => ReactNode;
  tooltip?: ReactNode | null;
  showError?: boolean;
  additionalFilters?: Record<string, string | number>;
  minInputValueLength?: number;
  testId?: string;
}

export const AutocompleteInput = ({
  label,
  name,
  onChange,
  placeholder,
  required = false,
  clearable = false,
  disabled = false,
  loading = false,
  limit = 50,
  loadItems,
  renderOption,
  tooltip,
  showError = true,
  additionalFilters,
  minInputValueLength,
  testId,
  ...rest
}: {
  defaultValue?: string | number;
  validate?: ValidateType[] | ValidateType;
} & AutocompleteInputProps &
  FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  const { input, meta } = useField(name, {
    ...rest,
  });

  return (
    <Input
      {...input}
      testId={testId}
      label={label}
      onChange={handleChange(input, onChange)}
      placeholder={placeholder}
      required={required}
      clearable={clearable}
      disabled={disabled}
      loading={loading}
      limit={limit}
      loadItems={loadItems}
      renderOption={renderOption}
      error={meta.touched && !!meta.error}
      helperText={showError && meta.touched && meta.error}
      tooltip={tooltip}
      additionalFilters={additionalFilters}
      minInputValueLength={minInputValueLength}
    />
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement } from 'react';

import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Button } from '../Button';

import { useStyles } from './DropdownButton.styles';

interface DropdownButtonProps {
  variant: 'secondary' | 'tertiaryLight' | 'quaternary';
  verticalPosition: 'top' | 'bottom';
  horizontalPosition: 'left' | 'right';
  menuItems: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onOpen?(): void;
  testId?: string;
  button?: (options: {
    onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
    isOpened: boolean;
    isDisabled: boolean;
  }) => ReactElement;
}

export const DropdownButton = ({
  variant,
  menuItems,
  verticalPosition,
  horizontalPosition,
  onOpen,
  disabled = false,
  button,
  testId,
}: DropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & Element)>(
    null,
  );

  const classes = useStyles();

  const handleOpen = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (disabled) {
    return button ? (
      <>
        {button({
          onClick: handleOpen,
          isOpened: !!anchorEl,
          isDisabled: disabled,
        })}
      </>
    ) : (
      <Button
        variant={variant}
        size="small"
        icon={
          <MoreVertIcon
            color={variant === 'quaternary' ? 'primary' : 'inherit'}
          />
        }
        disabled={disabled}
        onClick={handleOpen}
        disableRipple
      />
    );
  }

  return (
    <>
      {button ? (
        <>
          {button({
            onClick: handleOpen,
            isOpened: !!anchorEl,
            isDisabled: disabled,
          })}
        </>
      ) : (
        <Button
          variant={variant}
          size="small"
          testId={testId}
          icon={
            <MoreVertIcon
              color={variant === 'quaternary' ? 'primary' : 'inherit'}
            />
          }
          onClick={handleOpen}
          disableRipple
        />
      )}
      <Menu
        classes={{
          list: classes.menuList,
          paper: `${verticalPosition === 'bottom' ? classes.paper : ''}`,
        }}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        transformOrigin={{
          vertical: verticalPosition === 'top' ? 'bottom' : 'top',
          horizontal: horizontalPosition,
        }}
        transitionDuration={{
          appear: 200,
          enter: 200,
          exit: 0,
        }}
        data-testid="dropdownButton"
      >
        <div onClick={handleClose}>{menuItems}</div>
      </Menu>
    </>
  );
};

export const SIGNATURE_TYPE_OPTIONS = [
  {
    value: 'pep',
    label: 'ПЭП',
  },
  {
    value: 'unep',
    label: 'УНЭП/УКЭП',
  },
];

export const WITH_ORDER_OPTIONS = [
  {
    value: 'true',
    label: 'Да',
  },
  {
    value: 'false',
    label: 'Нет',
  },
];

export const SMS_CODE_LENGTH = 6;

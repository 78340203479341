import React, { ReactNode, useEffect } from 'react';

import InputMask from 'react-input-mask';

interface AutorecognitionType {
  children: ReactNode;
  recognizedValue?: string;
  isRecognitionUsedBefore: boolean;
  onBlur: () => void;
  disabled: boolean;
  value?: string;
  setValue: (recognizedValue?: string) => void;
  mask?: string;
}

export const Autorecognition = ({
  children,
  recognizedValue,
  isRecognitionUsedBefore,
  onBlur,
  disabled,
  value,
  setValue,
  mask,
}: AutorecognitionType) => {
  useEffect(() => {
    if (recognizedValue && !value) {
      if (mask) {
        const inputMask = new InputMask({
          mask,
          value: recognizedValue,
        }) as unknown as { value: string };

        setValue(inputMask.value);
      } else {
        setValue(recognizedValue);
      }
    }
  }, [setValue, recognizedValue, value, mask]);

  useEffect(() => {
    if (isRecognitionUsedBefore && !disabled && !recognizedValue) {
      onBlur();
    }
  }, [isRecognitionUsedBefore, disabled, onBlur]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { MultipleRadioInput as Input } from '../../input';

export const MultipleRadioInput = ({
  disabled = false,
  label,
  name,
  options,
  trueLabel,
  falseLabel,
  neverLabel,
  ...rest
}: {
  options: { label: string; id: string | number }[];
} & FieldProps<
  { id: string; value: 'always' | 'direct_only' | 'never' }[] | undefined,
  FieldRenderProps<
    { id: string; value: 'always' | 'direct_only' | 'never' }[] | undefined
  >
>) => {
  const [byHand, setByHand] = React.useState(false);

  return (
    <Field name={name} {...rest}>
      {({ input }) => {
        let groupValue: 'all' | 'none' | 'never' | 'hand' = 'hand';

        const values: {
          id: string | number;
          value: 'always' | 'direct_only' | 'never';
        }[] = options.map((option) => {
          const value =
            (input.value &&
              input.value.find((inputValue) => inputValue.id === option.id)) ||
            null;

          return value || { id: option.id, value: 'always' };
        });

        if (
          values.filter((opt) => opt.value === 'always').length ===
            options.length &&
          !byHand
        ) {
          groupValue = 'all';
        } else if (
          values.filter((opt) => opt.value === 'direct_only').length ===
            options.length &&
          !byHand
        ) {
          groupValue = 'none';
        } else if (
          values.filter((opt) => opt.value === 'never').length ===
            options.length &&
          !byHand
        ) {
          groupValue = 'never';
        }

        const handleChangeForAllElements = (optionValue: string | number) => {
          if (optionValue === 'all') {
            input.onChange(
              options.map((opt) => ({ id: opt.id, value: 'always' })),
            );
            setByHand(false);
          } else if (optionValue === 'none') {
            input.onChange(
              options.map((opt) => ({ id: opt.id, value: 'direct_only' })),
            );
            setByHand(false);
          } else if (optionValue === 'never') {
            input.onChange(
              options.map((opt) => ({ id: opt.id, value: 'never' })),
            );
            setByHand(false);
          } else {
            setByHand(true);
          }
        };

        const handleChangeForElement =
          (id: string | number) => (optionValue: string | number) => {
            const currentValue = values || [];

            if (optionValue === 'always') {
              input.onChange([
                ...currentValue.filter((opt) => opt.id !== id),
                { id, value: 'always' },
              ]);
              setByHand(false);
            } else if (optionValue === 'direct_only') {
              input.onChange([
                ...currentValue.filter((opt) => opt.id !== id),
                { id, value: 'direct_only' },
              ]);
              setByHand(false);
            } else if (optionValue === 'never') {
              input.onChange([
                ...currentValue.filter((opt) => opt.id !== id),
                { id, value: 'never' },
              ]);
              setByHand(false);
            }
          };

        return (
          <Input
            {...input}
            falseLabel={falseLabel}
            neverLabel={neverLabel}
            disabled={disabled}
            groupValue={groupValue}
            label={label}
            onChangeForAllElements={handleChangeForAllElements}
            onChangeForElement={handleChangeForElement}
            options={options}
            trueLabel={trueLabel}
            values={values}
            name={name}
          />
        );
      }}
    </Field>
  );
};

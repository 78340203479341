import {
  IsString,
  IsPhoneNumber,
  IsNotEmpty,
  IsOptional,
  Length,
  IsUUID,
  IsNumberString,
} from 'class-validator';

import {
  REQUIRED_FIELD_VALIDATION_MESSAGE,
  SMS_CODE_LENGTH,
} from '../../auth.constants';

export class LoginDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
  })
  phone?: string;

  @IsString({
    message: 'Вы забыли ввести пароль',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
  })
  password?: string;

  @Length(SMS_CODE_LENGTH, SMS_CODE_LENGTH, {
    message: 'Неверный СМС-код',
    groups: ['form'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['form'],
    },
  )
  @IsNotEmpty({
    groups: ['form'],
    message: 'Вы забыли ввести СМС-код',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СМС-код',
  })
  code: string;

  @IsOptional()
  @IsUUID()
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  inviteCode?: string;
}

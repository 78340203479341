/** Нормализовать путь
 * @param {string} path Исходный путь
 * @returns {string}
 * */
export const normalizePath = (path: string) =>
  path.startsWith('/') ? path : `/${path}`;

/** Получить из пути "название модуля"
 * @param {string} path Исходный путь
 * @returns {string}
 * */
export const getBasename = (path: string) =>
  normalizePath(path).split('/')[1].split('?')[0].split('#')[0];

/** Получить из пути "название конкретной веб-страницы"
 * @param {string} path Исходный путь
 * @returns {string}
 * */
export const getCurrentPath = (path: string) =>
  normalizePath(path).split('/').at(-1)?.split('?')[0].split('#')[0] || '/';

/** Убрать "название модуля" из пути
 * @returns {string}
 * */
export const removeBasename = (haystack: string, basenames?: string[]) => {
  if (!basenames || basenames.length === 0) {
    return haystack;
  }

  for (let i = 0; i < basenames.length; i++) {
    const normalizedBasename = normalizePath(basenames[i]);

    if (haystack.startsWith(normalizedBasename)) {
      return haystack.replace(normalizedBasename, '/').replace('//', '/');
    }
  }

  return haystack;
};

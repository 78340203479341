import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './translations.state';
import { translationsReducers } from './translations.actions';

export const translations = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    translationsReducers(builder);
  },
});

export const translationsReducer = translations.reducer;

import React, { useState, useEffect } from 'react';

import { TreeItem as MUITreeItem } from '@material-ui/lab';
import classNames from 'classnames';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';
import { Callout } from '../Callout';
import { Checkbox } from '../Checkbox';

import { Item } from './Item.interface';
import { useStyles } from './TreeItem.styles';

interface BaseTreeItemProps {
  item: Item;
  showRulers?: boolean;
  showCounters?: boolean;
  expanded?: string[];
  onSelect: (value: string, label?: string) => void;
  onExpand: (value: string) => void;
  onChildrenLoad?: (unitId: string) => Promise<Item[]>;
}

interface SingleSelectTreeItemProps extends BaseTreeItemProps {
  multiple?: false;
  selected: string;
}
interface MultiSelectTreeItemProps extends BaseTreeItemProps {
  multiple: true;
  selected: string[];
}

export type TreeItemProps =
  | SingleSelectTreeItemProps
  | MultiSelectTreeItemProps;

export const TreeItem = (props: TreeItemProps) => {
  const {
    item,
    showRulers = true,
    showCounters = true,
    expanded,
    onSelect,
    onExpand,
    onChildrenLoad,
  } = props;

  const [loadedChildren, setLoadedChildren] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const isExpanded = expanded?.some(
    (expandedItems) => expandedItems === item.id,
  );

  const { itemLabel, itemCounter, group, label, ...classes } = useStyles();

  useEffect(() => {
    if (
      isExpanded &&
      item.hasChildrenToLoad &&
      !item.children?.length &&
      !loadedChildren.length
    ) {
      setIsLoading(true);
      setIsError(false);
      onChildrenLoad?.(item.id)
        .then((result) => {
          setIsLoading(false);
          setLoadedChildren(result);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
          setLoadedChildren([]);
        });
    }
  }, [
    isExpanded,
    item.children?.length,
    item.hasChildrenToLoad,
    item.id,
    loadedChildren.length,
    onChildrenLoad,
  ]);

  const handleLabelClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(item.id, item.label);
  };

  const handleIconClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onExpand(item.id);
  };

  const getIsIndeterminate = (value: string[], option: Item): boolean => {
    if (value.includes(option.id)) {
      if (option.children) {
        if (!option.children.every((child) => value.includes(child.id))) {
          return true;
        } else {
          return option.children.every((child) =>
            getIsIndeterminate(value, child),
          );
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const childrenToShow =
    item?.children && !!item.children.length ? item.children : loadedChildren;
  const isShowChildren = !!childrenToShow.length || item.hasChildrenToLoad;

  return (
    <MUITreeItem
      nodeId={item.id}
      classes={{
        ...classes,
        ...(showRulers ? { group } : {}),
        label: classNames(label, 'aqa_tree_item'),
      }}
      onLabelClick={(event) => handleLabelClick(event)}
      onIconClick={(event) => handleIconClick(event)}
      label={
        <div className={itemLabel}>
          {props.multiple ? (
            <Box display="flex" alignItems="center" gap="8">
              <Checkbox
                checked={props.selected.includes(item.id)}
                indeterminate={getIsIndeterminate(props.selected, item)}
              />
              {item.label}
            </Box>
          ) : (
            <div>{item.label}</div>
          )}
          {showCounters && Number.isInteger(item.count) && (
            <div className={itemCounter}>{item.count}</div>
          )}
        </div>
      }
    >
      {isShowChildren && (
        <>
          {childrenToShow &&
            childrenToShow.map((child) => (
              <TreeItem
                key={child.id}
                item={child}
                showRulers={showRulers}
                showCounters={showCounters}
                onSelect={onSelect}
                onExpand={onExpand}
                onChildrenLoad={onChildrenLoad}
                expanded={expanded}
                {...(props.multiple
                  ? {
                      multiple: props.multiple,
                      selected: props.selected,
                    }
                  : { selected: props.selected })}
              />
            ))}
          {isLoading && (
            <Box display="flex" justifyContent="center" py="16">
              <CircularProgress size={20} />
            </Box>
          )}
          {isError && (
            <Box py="24" px="16">
              <Callout variant="error" showIcon>
                Не удалось загрузить оргструктуру. Обновите страницу и
                попробуйте еще раз
              </Callout>
            </Box>
          )}
        </>
      )}
    </MUITreeItem>
  );
};

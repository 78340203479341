import React, { useCallback, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { DocumentCopyIcon, GlobeCrossIcon } from '@vk-hr-tek/ui/icons';
import { Link } from '@vk-hr-tek/ui/Link';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { Logger } from '@vk-hr-tek/core/logger';
import {
  HTTP_BAD_REQUEST_STATUS,
  HTTP_INTERNAL_SERVER_ERROR_STATUS,
  HTTP_NOT_FOUND_STATUS,
  HTTP_SERVICE_UNAVAILABLE_STATUS,
} from '@vk-hr-tek/core/http/codes';

import { pageErrorDictionary } from './pageErrorDictionary';
interface PageErrorProps {
  status: number;
  errorMessage?: string;
  traceId?: string | null;
}

export const PageError = ({
  status,
  errorMessage,
  traceId,
}: PageErrorProps) => {
  const logger = useInject(Logger);
  const location = useLocation();
  const history = useHistory();

  const dictionary = pageErrorDictionary[status]
    ? pageErrorDictionary[status]
    : {
        title: 'Произошла ошибка',
        caption: 'Не удалось выполнить указанное действие. Попробуйте еще раз',
      };

  useEffect(() => {
    logger.info(`[ui] [${status}] GET ${location.pathname}`, {
      tags: {
        vkdoc_error_type: 'ui',
        http_status: status,
        http_trace_id: traceId || 'empty',
        app_url: location.pathname,
      },
      context: {
        app_error_message: {
          value: errorMessage || dictionary.caption,
        },
        ...(location.search
          ? {
              app_query: {
                search: location.search,
                hash: location.hash || null,
              },
            }
          : {}),
      },
    });
  }, [logger, errorMessage, location, status, dictionary.caption, traceId]);

  const supportMail = window.REACT_APP_VKHRTEK_SUPPORT_MAIL;
  const isShowHistoryBlock = status === HTTP_NOT_FOUND_STATUS;
  const isShowMailBlock =
    supportMail &&
    (status === HTTP_BAD_REQUEST_STATUS ||
      status === HTTP_INTERNAL_SERVER_ERROR_STATUS);
  const isShowTraceId =
    traceId &&
    (status === HTTP_BAD_REQUEST_STATUS ||
      status === HTTP_INTERNAL_SERVER_ERROR_STATUS);

  const handleGoMainPage = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCopy = useCallback(() => {
    if (traceId) {
      navigator.clipboard.writeText(traceId);
    }
  }, [traceId]);

  const handleMailTo = useCallback(() => {
    window.location.href = `mailto:${supportMail}`;
  }, [supportMail]);

  const marginTop =
    status === HTTP_INTERNAL_SERVER_ERROR_STATUS ||
    status === HTTP_NOT_FOUND_STATUS
      ? '72'
      : '40';

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={marginTop}
      height={
        status === HTTP_SERVICE_UNAVAILABLE_STATUS
          ? 'calc(100vh - 80px)'
          : 'auto'
      }
      gap="24"
    >
      <Box
        bgcolor="bg.light.primary"
        py={{ xs: '40', md: '48' }}
        px={{ xs: '16', md: '56' }}
        border={1}
        borderColor="stroke.primary"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={{ xs: 330, md: 502 }}
      >
        <GlobeCrossIcon />
        <Box
          display="flex"
          mt="32"
          gap="16"
          flexDirection="column"
          textAlign="center"
        >
          <Typography variant="h5">{dictionary.title}</Typography>
          <Typography variant="body3" color="text.light.secondary">
            {errorMessage || dictionary.caption}
          </Typography>
          {isShowHistoryBlock && (
            <Typography variant="body3" color="text.light.secondary">
              Попробуйте вернуться
              <Link variant="simple" size="small" onClick={handleGoBack}>
                {` назад `}
              </Link>
              или перейти на
              <Link variant="simple" size="small" onClick={handleGoMainPage}>
                {` главную страницу`}
              </Link>
            </Typography>
          )}
        </Box>

        {isShowTraceId && (
          <Box
            display="flex"
            alignItems="center"
            mt="48"
            gap="8"
            justifyContent="space-between"
          >
            <Typography noWrap color="text.light.secondary" variant="body3">
              <Box display="flex" gap="12">
                <Box>Код ошибки:</Box>
                <Box
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth={{ xs: 164, md: 238 }}
                  component="span"
                >
                  {traceId}
                </Box>
              </Box>
            </Typography>
            <IconButton type="button" size="small" onClick={handleCopy}>
              <DocumentCopyIcon color="primary" size="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      {isShowMailBlock && (
        <Box
          bgcolor="bg.light.primary"
          py={{ xs: '16', md: '24' }}
          px={{ xs: '16', md: '56' }}
          border={1}
          borderColor="stroke.primary"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={{ xs: 330, md: 502 }}
        >
          <Box display="flex" gap="8" flexDirection="column" textAlign="center">
            <Typography variant="subtitle2">
              {`Ошибка повторяется? Напишите нам ${
                traceId ? 'и сообщите код ошибки' : ''
              }`}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Link size="small" variant="simple" onClick={handleMailTo}>
                {supportMail}
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

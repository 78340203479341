import React from 'react';

import Divider from '@material-ui/core/Divider';

import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useTranslations } from '@vk-hr-tek/core/hooks';

import { selectHasCompanyUnits, selectManagebleUnits } from '../../../slice';

import { CompanyUnits } from './CompanyUnits';

export const CompanyUnitsList = () => {
  const managableUnits = useSelector(selectManagebleUnits);
  const hasCompanyUnits = useSelector(selectHasCompanyUnits);
  const isDesktop = useIsDesktop();
  const { t } = useTranslations();

  if (!managableUnits.length || !hasCompanyUnits) {
    return null;
  }

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      overflow="hidden"
    >
      <Accordion
        title={
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            Согласования по бизнес-процессам
          </Typography>
        }
        defaultExpanded
        detailsWithoutPaddings
      >
        <>
          <Box p="24" pb="0">
            <Typography
              variant={isDesktop ? 'body2' : 'body3'}
              color="text.light.tertirary"
            >
              {t('user.profile.companyUnits.helperSettingsText')}
            </Typography>
          </Box>
          {managableUnits.map((companyUnit, index, arr) => (
            <React.Fragment key={companyUnit.company.id}>
              <CompanyUnits companyUnit={companyUnit} />

              {arr.length - 1 !== index && <Divider />}
            </React.Fragment>
          ))}
        </>
      </Accordion>
    </Box>
  );
};

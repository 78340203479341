import { FORM_ERROR } from 'final-form';
import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  EntityState,
  createAction,
} from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { Crypto } from '@vk-hr-tek/core/crypto';
import { awaitDispatch } from '@vk-hr-tek/core/redux';
import { showNotification } from '@vk-hr-tek/core/notifications';

import {
  EventCompetencyOption,
  EventListItem as EventListEntity,
} from '@app/gen/events';
import { ThunkExtra, ThunkValuesAndActions } from '@app/store';

import {
  SignUnepDto,
  SignPepDto,
  UploadDocumentDto,
  AcceptDto,
  GenerateDocumentDto,
  GetEventDto,
  DeclineDto,
  DeclineSignDto,
  SignPepBatchDto,
  MarkReadDto,
  UnepLocalDto,
  UnepLocalBatchDto,
  CompetencyEvalDto,
  GetCompetencyOptionsDto,
  CompetencyProfileDto,
} from '../../dto';
import {
  EventsDetailService,
  EventsListService,
  EventCacheService,
} from '../../services';
import {
  EventsState,
  EventsWithRootState,
  setCodeRequestingError,
  setCodeRequestingLoading,
  setCodeRequestingSuccess,
} from '../events.state';
import { FormAttributesRequest } from '../../types';
import { CandidatesRouter } from '../../../candidates/types';

import { getEvent } from './detail.actions';

export const signUnep = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnep',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).signUnep(values);

      if (values.skipSms) {
        await awaitDispatch(
          dispatch(
            getEvent(
              plainToClass(GetEventDto, { id: values.eventId, blink: true }),
            ),
          ),
        );
      }

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const verifySignUnep = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/verifySignUnep',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).verifySignUnep(values);

      await awaitDispatch(
        dispatch(
          getEvent(
            plainToClass(GetEventDto, { id: values.eventId, blink: true }),
          ),
        ),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUnepBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).signUnepBatch(values);

      if (values.skipSms) {
        await awaitDispatch(
          dispatch(
            getEvent(
              plainToClass(GetEventDto, { id: values.eventId, blink: true }),
            ),
          ),
        );
      }

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const verifySignUnepBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/verifySignUnepBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(EventsDetailService).verifySignUnepBatch(values);

      await awaitDispatch(
        dispatch(
          getEvent(
            plainToClass(GetEventDto, { id: values.eventId, blink: true }),
          ),
        ),
      );

      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signPep = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignPepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signPep',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).signPep(values);

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signPepBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<SignPepBatchDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signPepBatch',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).signPepBatch(values);

      cache.deleteValue(values.events[0].event_id);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUkep = createAsyncThunk<
  void,
  {
    values: {
      eventId: string;
      documentId: string;
      nodeId: string;
      certificateId: string;
      attributes?: FormAttributesRequest;
      tsp?: string;
    };
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: { [FORM_ERROR]: unknown }) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'events/signUkep',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      const service = await inject(EventsDetailService);
      const crypto = await inject(Crypto);

      const [{ hash }, certificate] = await Promise.all([
        service
          .getDocumentHash({
            eventId: values.eventId,
            documentId: values.documentId,
          })
          .catch(() => {
            throw new Error('Ошибка получения хэша документа');
          }),
        crypto.getSertificateById(values.certificateId),
      ]);

      const signature = await crypto
        .signHash(certificate, hash, values.tsp)
        .catch((err) => {
          throw new AppError('client', {
            code: 500,
            message: err?.message ?? 'Ошибка при подписании',
            data: {
              error_code: values?.tsp ? 'sign_hash_failed' : '',
            },
          });
        });

      await service.signUkep({
        eventId: values.eventId,
        nodeId: values.nodeId,
        attributes: values.attributes,
        signature,
      });

      await awaitDispatch(
        dispatch(getEvent({ id: values.eventId, blink: true })),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      if (err instanceof AppError) {
        actions.reject({ [FORM_ERROR]: err });
        return rejectWithValue(classToPlain(err) as AppError);
      }

      const appError = new AppError('client', {
        code: 500,
        message: 'Упс! Что-то пошло не так.',
      });

      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      actions.reject({ [FORM_ERROR]: appError });

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const signUnepLocal = createAsyncThunk<
  void,
  ThunkValuesAndActions<UnepLocalDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepLocal',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      const service = await inject(EventsDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);
      let hash = '';

      if (values.hashSource === 'browser_plugin') {
        const [{ file: blob }] = await service.getDocuments({
          documents: [
            {
              documentId: values.documentId,
              eventId: values.eventId,
            },
          ],
        });

        hash = await crypto.createHash(await blob);
      } else {
        const result = await service.getDocumentHash({
          eventId: values.eventId,
          documentId: values.documentId,
        });

        hash = result.hash;
      }

      const signature = await crypto
        .signHash(certificate, hash, values.tsp)
        .catch((err) => {
          throw new AppError('client', {
            code: 500,
            message: err?.message ?? 'Ошибка при подписании',
            data: {
              error_code: values?.tsp ? 'sign_hash_failed' : '',
            },
          });
        });

      await service.signUnepLocal({
        eventId: values.eventId,
        nodeId: values.nodeId,
        attributes: values.attributes,
        signatures: [
          {
            signature,
            hash,
          },
        ],
      });

      await awaitDispatch(
        dispatch(getEvent({ id: values.eventId, blink: true })),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      if (err instanceof AppError) {
        actions.reject({ [FORM_ERROR]: err });
        return rejectWithValue(classToPlain(err) as AppError);
      }

      const appError = new AppError('client', {
        code: 500,
        message: 'Упс! Что-то пошло не так.',
      });

      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      actions.reject({ [FORM_ERROR]: appError });

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const signUnepLocalBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<UnepLocalBatchDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepLocalBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const listService = await inject(EventsListService);
      const detailService = await inject(EventsDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);
      let hashes: string[];

      if (values.hashSource === 'browser_plugin') {
        const result = await detailService.getDocuments({
          documents: values.documentIds.map((documentId) => ({
            documentId,
            eventId: values.eventId,
          })),
        });

        hashes = await Promise.all(
          result.map(async ({ file: blob }) => {
            return crypto.createHash(await blob);
          }),
        );
      } else {
        const response = await listService.getHashes({
          hashes: [
            {
              event_id: values.eventId,
            },
          ],
        });

        hashes = response.hashes.map(({ hash }) => hash);
      }

      const signatures = await Promise.all(
        hashes.map(async (hash) => {
          return {
            hash,
            signature: await crypto
              .signHash(certificate, hash, values.tsp)
              .catch((err) => {
                throw new AppError('client', {
                  code: 500,
                  message: err?.message ?? 'Ошибка при подписании',
                  data: {
                    error_code: values?.tsp ? 'sign_hash_failed' : '',
                  },
                });
              }),
          };
        }),
      );

      try {
        await detailService.signUnepLocalBatch({
          eventId: values.eventId,
          nodeId: values.nodeId,
          attributes: values.attributes,
          signatures,
        });

        await awaitDispatch(
          dispatch(getEvent({ id: values.eventId, blink: true })),
        );
      } catch (error) {
        throw new Error('Ошибка отправки подписи');
      }

      actions.resolve(null);
    } catch (err) {
      if (err instanceof AppError) {
        actions.reject({ [FORM_ERROR]: err });
        return rejectWithValue(classToPlain(err) as AppError);
      }

      const appError = new AppError('client', {
        code: 500,
        message: 'Упс! Что-то пошло не так.',
      });

      /* istanbul ignore next */
      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      actions.reject({ [FORM_ERROR]: appError });

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const signUkepBatchDetail = createAsyncThunk<
  void,
  {
    values: {
      data: {
        event_id: string;
        document_id: string;
      }[];
      tsp?: string;
      eventId: string;
      certificateId: string;
    };
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: { [FORM_ERROR]: AppError }) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'events/companySignBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = await inject(EventsListService);
      const crypto = await inject(Crypto);
      const cache = inject(EventCacheService);

      const [{ hashes }, certificate] = await Promise.all([
        service
          .getHashes({
            hashes: values.data.map((value) => ({
              event_id: value.event_id,
            })),
          })
          .catch(() => {
            throw new Error('Ошибка получения хэша документа');
          }),
        crypto.getSertificateById(values.certificateId),
      ]);

      const dataWithSignatures = await Promise.all(
        hashes.map(async (hash) => {
          return {
            ...hash,
            signature: await crypto
              .signHash(certificate, hash.hash, values.tsp)
              .catch((err) => {
                throw new AppError('client', {
                  code: 500,
                  message: err?.message ?? 'Ошибка при подписании',
                  data: {
                    error_code: values?.tsp ? 'sign_hash_failed' : '',
                  },
                });
              }),
          };
        }),
      );

      interface CompanyBatchSignRequest {
        [key: string]: {
          event_id: string;
          documents: {
            signature: string;
            document_hash: string;
          }[];
        };
      }

      const transformedData = dataWithSignatures.reduce(
        (acc: CompanyBatchSignRequest, item) => {
          acc[item.event_id] = {
            event_id: item.event_id,
            documents: [
              ...((acc[item.event_id] && acc[item.event_id].documents) || []),
              {
                signature: item.signature,
                document_hash: item.hash,
              },
            ],
          };
          return acc;
        },
        {},
      );

      try {
        await service.ukepBatchSign({
          events: Object.values(transformedData),
        });

        await awaitDispatch(
          dispatch(getEvent({ id: values.eventId, blink: true })),
        );
      } catch (error) {
        throw new Error('Ошибка отправки подписи');
      }

      cache.deleteValue(values.eventId);
      actions.resolve(null);
    } catch (err) {
      if (err instanceof AppError) {
        actions.reject({ [FORM_ERROR]: err });
        return rejectWithValue(classToPlain(err) as AppError);
      }

      const appError = new AppError('client', {
        code: 500,
        message: 'Упс! Что-то пошло не так.',
      });

      /* istanbul ignore next */
      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      actions.reject({ [FORM_ERROR]: appError });

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const uploadDocument = createAsyncThunk<
  void,
  ThunkValuesAndActions<UploadDocumentDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/uploadDocument',
  async (
    { values, actions },
    { rejectWithValue, dispatch, getState, extra: { inject } },
  ) => {
    const router = inject<CandidatesRouter>(CandidatesRouter);
    const cache = inject(EventCacheService);

    try {
      const eventDetail = getState().events.detail.data;
      const nodeById = eventDetail?.nodes.find(
        (node) => node.node_id === values.nodeId,
      );

      await inject(EventsDetailService).uploadDocument(values);

      if (
        nodeById?.actions.some(
          (action) =>
            action.type === 'upload' &&
            action.extra?.marker === 'candidate_notify',
        )
      ) {
        router.goToList();

        dispatch(showNotification('Анкета создана и отправлена кандидату'));
      } else {
        dispatch(
          getEvent(
            plainToClass(GetEventDto, { id: values.eventId, blink: true }),
          ),
        );
      }

      cache.deleteValue(values.eventId);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const accept = createAsyncThunk<
  void,
  ThunkValuesAndActions<AcceptDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/accept',
  async (
    { values, actions },
    { rejectWithValue, dispatch, getState, extra: { inject } },
  ) => {
    const router = inject<CandidatesRouter>(CandidatesRouter);
    const cache = inject(EventCacheService);

    try {
      const eventDetail = getState().events.detail.data;
      const nodeById = eventDetail?.nodes.find(
        (node) => node.node_id === values.nodeId,
      );

      await inject(EventsDetailService).accept(values);

      if (
        nodeById?.actions.some(
          (action) =>
            action.type === 'accept' &&
            action.extra?.marker === 'candidate_notify',
        )
      ) {
        router.goToList();

        dispatch(showNotification('Анкета создана и отправлена кандидату'));
      } else {
        dispatch(
          getEvent(
            plainToClass(GetEventDto, { id: values.eventId, blink: true }),
          ),
        );
      }

      cache.deleteValue(values.eventId);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const competencyEval = createAsyncThunk<
  void,
  ThunkValuesAndActions<CompetencyEvalDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/competencyEval',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).competencyEval(values);

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const competencyProfile = createAsyncThunk<
  void,
  ThunkValuesAndActions<CompetencyProfileDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/competencyProfile',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      await inject(EventsDetailService).competencyProfile(values);

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCompetencyOptions = createAsyncThunk<
  EventCompetencyOption[],
  GetCompetencyOptionsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getCompetencyOptions',
  async (values, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(EventsDetailService).getCompetencyOptions(
        values,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const decline = createAsyncThunk<
  void,
  ThunkValuesAndActions<DeclineDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/decline',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(EventsDetailService).decline(values);
      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const declineSign = createAsyncThunk<
  void,
  ThunkValuesAndActions<DeclineSignDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/declineSign',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(EventsDetailService).declineSign(values);
      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const generateDocument = createAsyncThunk<
  void,
  ThunkValuesAndActions<GenerateDocumentDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/generateDocument',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);

      await inject(EventsDetailService).generateDocument(values);
      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUnepGoskey = createAsyncThunk<
  unknown,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepGoskey',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      const result = await inject(EventsDetailService).signUnep(values);

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUnepGoskeyMobile = createAsyncThunk<
  unknown,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepGoskeyMobile',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const result = await inject(EventsDetailService).signUnep(values);
      window.open('https://goskey.ru/');

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      actions.resolve(null);
      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUnepBatchGoskey = createAsyncThunk<
  unknown,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepBatchGoskey',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      const result = await inject(EventsDetailService).signUnepBatch(values);

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const signUnepBatchGoskeyMobile = createAsyncThunk<
  unknown,
  ThunkValuesAndActions<SignUnepDto>,
  ThunkExtra<EventsWithRootState>
>(
  'events/signUnepBatchGoskey',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const cache = inject(EventCacheService);
      const result = await inject(EventsDetailService).signUnepBatch(values);
      window.open('https://goskey.ru/');

      dispatch(
        getEvent(
          plainToClass(GetEventDto, { id: values.eventId, blink: true }),
        ),
      );

      cache.deleteValue(values.eventId);
      actions.resolve(null);
      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const markDocumentRead = createAsyncThunk<void, MarkReadDto, ThunkExtra>(
  'events/markDocumentRead',
  async (markReadDto, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(EventsDetailService).markRead(markReadDto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const resetCompetencyProfileOptions = createAction(
  'events/resetCompetencyProfileOptions',
);

export const eventActionsReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(signPep.pending, (state) => {
    state.pepSign.status = 'loading';
  });
  builder.addCase(signPep.fulfilled, (state) => {
    state.pepSign.status = 'complete';
  });
  builder.addCase(signPep.rejected, (state) => {
    state.pepSign.status = 'failed';
  });
  builder.addCase(signPepBatch.pending, (state) => {
    state.pepSignBatch.status = 'loading';
  });
  builder.addCase(signPepBatch.fulfilled, (state) => {
    state.pepSignBatch.status = 'complete';
  });
  builder.addCase(signPepBatch.rejected, (state) => {
    state.pepSignBatch.status = 'failed';
  });

  builder.addCase(signUkep.pending, (state) => {
    state.ukepSign.status = 'loading';
  });
  builder.addCase(signUkep.fulfilled, (state) => {
    state.ukepSign.status = 'complete';
  });
  builder.addCase(signUkep.rejected, (state) => {
    state.ukepSign.status = 'failed';
  });
  builder.addCase(signUkepBatchDetail.pending, (state) => {
    state.ukepSignBatch.status = 'loading';
  });
  builder.addCase(signUkepBatchDetail.fulfilled, (state) => {
    state.ukepSignBatch.status = 'complete';
  });
  builder.addCase(signUkepBatchDetail.rejected, (state) => {
    state.ukepSignBatch.status = 'failed';
  });
  builder.addCase(verifySignUnep.pending, (state) => {
    state.unepSign.status = 'loading';
  });
  builder.addCase(verifySignUnep.fulfilled, (state) => {
    state.unepSign.status = 'complete';
  });
  builder.addCase(verifySignUnep.rejected, (state) => {
    state.unepSign.status = 'failed';
  });
  builder.addCase(verifySignUnepBatch.pending, (state) => {
    state.unepSignBatch.status = 'loading';
  });
  builder.addCase(verifySignUnepBatch.fulfilled, (state) => {
    state.unepSignBatch.status = 'complete';
  });
  builder.addCase(verifySignUnepBatch.rejected, (state) => {
    state.unepSignBatch.status = 'failed';
  });

  builder.addCase(signUnep.pending, (state) => {
    setCodeRequestingLoading(state);
  });
  builder.addCase(signUnep.fulfilled, (state) => {
    setCodeRequestingSuccess(state);
  });
  builder.addCase(signUnep.rejected, (state, { payload, error }) => {
    setCodeRequestingError(state, { payload, error });
  });

  builder.addCase(signUnepBatch.pending, (state) => {
    setCodeRequestingLoading(state);
  });
  builder.addCase(signUnepBatch.fulfilled, (state) => {
    setCodeRequestingSuccess(state);
  });
  builder.addCase(signUnepBatch.rejected, (state, { payload, error }) => {
    setCodeRequestingError(state, { payload, error });
  });

  builder.addCase(getCompetencyOptions.pending, (state) => {
    state.competencyOptions.status = 'loading';
    state.competencyOptions.items = [];
  });
  builder.addCase(getCompetencyOptions.fulfilled, (state, { payload }) => {
    state.competencyOptions.status = 'complete';
    state.competencyOptions.items = payload;
  });
  builder.addCase(
    getCompetencyOptions.rejected,
    (state, { payload, error }) => {
      state.competencyOptions.status = 'failed';
      state.competencyOptions.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(competencyProfile.fulfilled, (state) => {
    state.competencyOptions.items = [];
  });
  builder.addCase(resetCompetencyProfileOptions, (state) => {
    state.competencyOptions.items = [];
  });
};

import React, { useEffect } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useSelector, useDispatch } from '@app/hooks';

import { useLogout } from '../../../auth';
import {
  selectCertificateSignType,
  selectPhoneNumber,
  pollUser,
} from '../../slice';
import { Page } from '../../ui/page';

export const CertificateWaiting = () => {
  const dispatch = useDispatch();
  const logout = useLogout();

  const signType = useSelector(selectCertificateSignType);
  const phone = useSelector(selectPhoneNumber);
  const authInstance = useSelector(selectAuthInstance);

  const unepTypeTitle =
    signType === 'cryptopro_simple' ? 'КриптоПро' : 'СКБ Контур';

  useEffect(() => {
    dispatch(pollUser());
  }, [dispatch]);

  return (
    <Page
      title={`Выпуск электронной подписи в ${unepTypeTitle}`}
      theme="notification"
    >
      <Box mb="20">
        <Typography variant="body3" color="text.light.secondary">
          Когда подпись будет готова, мы отправим вам уведомление на номер:
          <br />
          <b>{phone}</b>
        </Typography>
      </Box>
      <Box mb="40">
        <Typography variant="body3" color="text.light.secondary">
          Выпуск УНЭП, как правило, занимает не более 10 минут. После успешного
          выпуска подписи вы получите полный доступ к работе с документами
        </Typography>
        {authInstance.auth_type === 'pass' && (
          <Box mt="20">
            <Link onClick={logout} size="small" variant="tertiary">
              Выход к QR-коду
            </Link>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="center">
        <CircularProgress size={50} />
      </Box>
    </Page>
  );
};

import React, { useCallback, useState, useRef } from 'react';

import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Slide, useTheme } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { ImageConverter } from '@vk-hr-tek/core/image-converter';
import { ImageRotator } from '@vk-hr-tek/core/image-rotator';
import { FilesService } from '@vk-hr-tek/core/files/files.service';
import { useInject } from '@vk-hr-tek/core/ioc';

import { useIsDesktop } from '../../hooks';
import { BasketIcon, RotateLeftIcon, CancelIcon, FileIcon } from '../../icons';
import { Link } from '../../Link';
import { Tooltip } from '../../Tooltip';
import { Button } from '../../Button';
import { FilePreview } from '../../FilePreview';
import { Box } from '../../Box';
import { Grid } from '../../Grid';
import { Paper } from '../../Paper';
import { CircularProgress } from '../../CircularProgress';
import { Typography } from '../../Typography';
import { FileError, Label, HelperText } from '../common';

import { FillBanner } from './FillBanner';
import { FileInputValue } from './FileInput.intefaces';
import useStyles from './FileInput.styles';

interface FileInputProps {
  value: FileInputValue | undefined;
  onChange: (value: FileInputValue | undefined) => void;
  onBlur?: () => void;
  label: string;
  tooltip?: React.ReactNode;
  variant?: 'input' | 'preview';
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
  fullWidth?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  onCacheSuccess?: (cacheId: string) => void;
  onCacheError?: () => void;
  bannerText?: string;
  testId?: string;
}

export const FileInput = ({
  value,
  onChange,
  onBlur = () => {},
  label,
  tooltip,
  variant = 'input',
  placeholder = 'Перенесите файл, что бы прикрепить документ',
  required = false,
  disabled = false,
  loading = false,
  cache,
  fullWidth,
  error = false,
  errorText = '',
  helperText = '',
  onCacheSuccess = () => {},
  onCacheError = () => {},
  bannerText,
  testId = 'test-id-file-input',
  ...rest
}: FileInputProps) => {
  const classes = useStyles();
  const filesService = useInject(FilesService);
  const isDesktop = useIsDesktop();
  const imageRotator = useInject(ImageRotator);
  const imageConverter = useInject(ImageConverter);
  const previewBlock = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const [isFileLoading, setIsFileLoading] = useState(loading);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const [cacheFileName, setCacheFilename] = useState('');
  const [blobDocument, setBlobDocument] = useState<Blob | null>(null);

  const isLoading = loading || isFileLoading;
  const isDisabled = disabled || isLoading;

  const previewWidth = 296;
  let previewHeight;

  if (isDesktop || (value && !error && !isLoading)) {
    previewHeight = 418;
  } else if (bannerText && !value) {
    previewHeight = 144;
  } else {
    previewHeight = 92;
  }

  const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/apng',
    'image/gif',
    'image/webp',
    'image/avif',
  ];

  const previewBlockRect = previewBlock?.current?.getBoundingClientRect();
  const previewBlockWidth = previewBlockRect?.width ?? previewWidth;

  const previewResponsiveHeight =
    (previewHeight * previewBlockWidth) / previewWidth;

  const handleChange = useCallback(
    (nextValue: FileInputValue | undefined) => {
      if (cacheFileName) setCacheFilename('');

      onChange(nextValue);
    },
    [onChange, cacheFileName],
  );

  const load: () => Promise<Blob> = useCallback(async () => {
    if (blobDocument) {
      return blobDocument;
    }

    if (typeof value?.source === 'string') {
      const fileData = await filesService.getFile(value.source);
      const { file, filename } = await fileData;

      if (filename) {
        setCacheFilename(filename);
      }

      setBlobDocument(await file);
      return file;
    } else {
      if (value?.source) {
        setBlobDocument(value.source);
      }

      return value?.source || new Blob();
    }
  }, [filesService, value?.source, blobDocument]);

  const onDrop = async (files: File[]) => {
    if (files && files.length) {
      setIsFileLoading(true);

      const uploadedFile = files[0];
      const isWrongFormat = [
        'image/heic',
        'image/heif',
        'image/tiff',
        'image/dng',
        'image/x-adobe-dng',
      ].includes(uploadedFile.type);

      if (cache) {
        if (isWrongFormat) {
          const [fileCacheResponse, blob] = await Promise.all([
            cache(uploadedFile).catch(() => null),
            imageConverter.convert(uploadedFile).catch(() => null),
          ]);

          const cacheId = fileCacheResponse?.file_id;
          const cacheExpiredAt = fileCacheResponse?.expired_at;

          const convertedFile =
            blob &&
            new File([blob], 'image.jpeg', {
              type: blob.type,
            });

          setBlobDocument(convertedFile || new Blob());

          handleChange({
            value:
              cacheId && cacheExpiredAt
                ? JSON.stringify({
                    type: 'file_cache',
                    id: cacheId,
                    expiredAt: cacheExpiredAt,
                  })
                : convertedFile || uploadedFile,
            source: convertedFile || uploadedFile,
          });
        } else {
          try {
            const { file_id: cacheId, expired_at: cacheExpiredAt } =
              await cache(uploadedFile);

            setBlobDocument(uploadedFile);
            handleChange({
              value: JSON.stringify({
                type: 'file_cache',
                id: cacheId,
                expiredAt: cacheExpiredAt,
              }),
              source: uploadedFile,
            });
            onCacheSuccess(cacheId.toString());
          } catch (e) {
            onCacheError();
          }
        }
      } else if (!cache) {
        if (isWrongFormat) {
          const blob = await imageConverter.convert(uploadedFile);
          const convertedFile = new File([blob], 'image.jpeg', {
            type: blob.type,
          });

          setBlobDocument(blob);
          handleChange({
            value: convertedFile,
            source: convertedFile,
          });
        } else {
          setBlobDocument(uploadedFile);
          handleChange({
            value: uploadedFile,
            source: uploadedFile,
          });
        }
      }

      setIsFileLoading(false);
    } else {
      handleChange(undefined);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (document?.activeElement as any)?.blur();
    onBlur();
  };

  const { open, getRootProps, getInputProps } = useDropzone({
    disabled: isDisabled,
    onDrop,
  });

  const rotateDocument = () => {
    load().then((blob: Blob) => {
      imageRotator.rotate(blob).then((updatedBlob: Blob | null) => {
        if (updatedBlob) {
          const format = updatedBlob.type.split('/').pop();

          const file = new File([updatedBlob], `image.${format}`, {
            type: updatedBlob.type,
          });

          onDrop([file]);
        }
      });
    });
  };

  const removeDocument = () => {
    handleChange(undefined);
    setIsButtonsVisible(false);
  };

  const createInputText = useCallback(() => {
    if (isLoading) {
      return 'Загрузка...';
    } else if (value) {
      return (
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (value?.source as any)?.name ||
        (typeof value?.value === 'string' && (cacheFileName || label))
      );
    }

    return placeholder;
  }, [cacheFileName, isLoading, label, placeholder, value]);

  const inputLabel = label ? (
    <Label label={label} required={required} tooltip={tooltip} />
  ) : null;

  const inputVariantDropzone = (
    <div
      {...getRootProps({ className: 'dropzone aqa_file_input_dropzone' })}
      data-testid={testId}
    >
      <input {...rest} {...getInputProps()} />
      <div
        className={classNames(
          classes.dropzone,
          error && classes.error,
          isDisabled && classes.disabled,
        )}
      >
        <Typography
          variant="body2"
          color={error || value ? 'text.light.primary' : 'text.light.tertirary'}
          className={classes.inputText}
        >
          {createInputText()}
        </Typography>
      </div>
    </div>
  );

  const inputVariantButton = (
    <Button
      variant="secondary"
      size="large"
      onClick={open}
      disabled={disabled}
      icon={
        isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <AttachFileIcon className={classes.icon} />
        )
      }
    />
  );

  return variant === 'input' ? (
    <Box className={classNames(classes.fileInput, 'aqa_file_input')}>
      {fullWidth ? (
        <Box>
          {inputLabel}
          <Box display="flex" gap="20">
            <Box flexGrow={1} maxWidth="calc(100% - 76px)">
              {inputVariantDropzone}
            </Box>
            <Box>{inputVariantButton}</Box>
          </Box>
        </Box>
      ) : (
        <Grid container spacing="20">
          <Grid item xs={9} md={8}>
            {inputLabel}
            {inputVariantDropzone}
          </Grid>
          <Grid item xs={3} md={4}>
            <Box display="flex" height="100%" alignItems="end">
              {inputVariantButton}
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} md={12}>
          {error ? (
            <FileError text={errorText} />
          ) : (
            helperText && !value && <HelperText text={helperText} />
          )}
        </Grid>
      </Grid>
      {value && !error && (
        <Box
          mt={{ xs: '8', md: '16' }}
          width={{ xs: 96, md: 144 }}
          height={{ xs: 96, md: 144 }}
        >
          <Box position="relative">
            {!disabled && (
              <Box
                position="absolute"
                top={7}
                right={7}
                zIndex={1}
                width={24}
                height={24}
                overflow="hidden"
                bgcolor="text.dark.primary"
                border={2}
                borderColor="text.dark.primary"
                radius="round"
                onClick={removeDocument}
              >
                <Box
                  position="absolute"
                  width={24}
                  height={24}
                  top={-2}
                  left={-2}
                >
                  <CancelIcon color="disabled" />
                </Box>
              </Box>
            )}
            <Paper>
              <FilePreview shape="square" onLoad={load} isClickable />
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box width={fullWidth ? '100%' : previewWidth} testId={testId}>
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      <Box position="relative">
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height={418}
            bgcolor="bg.light.primary"
            border={`${theme.tokens.border.m}px dashed`}
            borderColor="stroke.primary"
            radius="l"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={fullWidth ? previewResponsiveHeight : previewHeight}
            >
              {bannerText && !value && <FillBanner>{bannerText}</FillBanner>}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width={{ xs: 154, md: 200 }}
                mt={!isDesktop && bannerText && !value ? '8' : '0'}
                gap="8"
              >
                <CircularProgress size={20} />
                <Box
                  mb={{ xs: '0', md: '12' }}
                  color={
                    isDesktop
                      ? 'text.light.tertirary'
                      : 'original.brand.primary'
                  }
                >
                  <Typography
                    display="block"
                    variant={isDesktop ? 'body2' : 'caption'}
                    color="inherit"
                    align="center"
                  >
                    Пожалуйста, подождите
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...rest} {...getInputProps()} />
            <div
              ref={previewBlock}
              className={classNames(
                classes.previewDropzone,
                error && classes.error,
                isDisabled && classes.disabled,
              )}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={fullWidth ? previewResponsiveHeight : previewHeight}
              >
                {bannerText && !value && <FillBanner>{bannerText}</FillBanner>}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width={{ xs: 154, md: 200 }}
                  mt={!isDesktop && bannerText && !value ? '56' : '0'}
                >
                  <Box
                    mb="4"
                    height={{ xs: 32, md: 56 }}
                    fontSize={{ xs: 32, md: 56 }}
                  >
                    <FileIcon />
                  </Box>
                  <Box
                    mb={{ xs: '0', md: '12' }}
                    color={
                      isDesktop
                        ? 'text.light.tertirary'
                        : 'original.brand.primary'
                    }
                  >
                    <Typography
                      display="block"
                      variant={isDesktop ? 'body2' : 'caption'}
                      color="inherit"
                      align="center"
                    >
                      Загрузите изображение или PDF файл
                    </Typography>
                  </Box>
                  {isDesktop && (
                    <Button
                      variant="secondary"
                      size="small"
                      disabled={disabled}
                    >
                      Загрузить
                    </Button>
                  )}
                </Box>
              </Box>
            </div>
          </div>
        )}
        {value && !error && !isLoading && (
          <div
            className={classes.filePreview}
            onMouseEnter={() => setIsButtonsVisible(true)}
            onMouseLeave={() => setIsButtonsVisible(false)}
          >
            <Box position="relative" width="100%" height="100%">
              <Box height="100%">
                <FilePreview withFlex onLoad={load} />
              </Box>
              {!isDesktop && (
                <Box
                  position="absolute"
                  top={16}
                  right={16}
                  zIndex={1}
                  width={24}
                  height={24}
                  overflow="hidden"
                  bgcolor="text.dark.primary"
                  border={2}
                  borderColor="text.dark.primary"
                  radius="round"
                  onClick={removeDocument}
                >
                  <Box
                    position="absolute"
                    width={24}
                    height={24}
                    top={-2}
                    left={-2}
                  >
                    <CancelIcon color="disabled" />
                  </Box>
                </Box>
              )}
            </Box>
            {isDesktop && (
              <Slide
                direction="up"
                in={isButtonsVisible}
                mountOnEnter
                unmountOnExit
                timeout={500}
              >
                <div className={classes.previewButtons}>
                  <Box>
                    {blobDocument?.type.startsWith('image/') && (
                      <Tooltip
                        title="Повернуть изображение против часовой стрелки"
                        placement="bottom"
                      >
                        <div>
                          <Link
                            size="medium"
                            onClick={rotateDocument}
                            startIcon={<RotateLeftIcon />}
                            stroke={false}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Box>
                  <Link
                    size="small"
                    onClick={removeDocument}
                    endIcon={<BasketIcon size="small" />}
                    stroke={false}
                  >
                    Удалить
                  </Link>
                </div>
              </Slide>
            )}
            {!isDesktop && allowedTypes.includes(blobDocument?.type || '') && (
              <Box position="absolute" right={16} bottom={16}>
                <Button
                  variant="quaternary"
                  icon={<RotateLeftIcon className={classes.rotateIcon} />}
                  onClick={rotateDocument}
                />
              </Box>
            )}
          </div>
        )}
      </Box>
      {error && <FileError text={errorText} />}
    </Box>
  );
};

import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  GetCompanyEventTypesResponse,
  GetUnitResponse,
} from '@app/gen/settings';

import { GetCompanyUnitDto, UpdateCompanyUnitDto } from '../dto';

@injectable()
export class UserUnitsService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async updateCompanyUnit(dto: UpdateCompanyUnitDto) {
    await this.validator.validateOrReject(dto, UpdateCompanyUnitDto, [
      'submit',
    ]);

    const result = await this.http.put(
      `/company/${dto.companyId}/unit/${dto.unitId}`,
      {
        name: dto.name,
        ...(dto.parentId ? { parent_id: dto.parentId } : {}),
        manager_id: dto.managerId,
        manager_settings: dto.managerSettings.map(({ id, value }) => ({
          event_type_id: id,
          approve: value,
        })),
      },
      { withSide: true },
    );

    return result;
  }

  async getCompanyUnit(dto: GetCompanyUnitDto) {
    await this.validator.validateOrReject(dto, GetCompanyUnitDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${dto.companyId}/unit/${dto.unitId}`,
        {},
        { withSide: true },
      ),
      GetUnitResponse,
    );

    return result;
  }

  async getCompanyUnitEventTypes(dto: { companyId: string }) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${dto.companyId}/event_types`,
        {
          has_manager: true,
          manager_type: 'operational',
        },
        { withSide: true },
      ),
      GetCompanyEventTypesResponse,
    );

    return result;
  }
}

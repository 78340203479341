/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { FileInput as Input } from '../../input';

type FileInputProps = {
  label: string;
  tooltip?: React.ReactNode;
  name: string;
  variant?: 'input' | 'preview';
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
  fullWidth?: boolean;
  bannerText?: string;
  helperText?: string;
  testId?: string;
};

export const FileInput = ({
  label,
  tooltip,
  name,
  variant = 'input',
  placeholder,
  required = false,
  disabled = false,
  loading = false,
  validate,
  cache,
  fullWidth,
  bannerText,
  helperText,
  testId,
}: FileInputProps) => {
  const { input, meta } = useField(name, {
    validate: compose(validate || []),
  });

  const { onChange, onBlur } = input;

  return (
    <Input
      {...input}
      label={label}
      value={input.value}
      onChange={onChange}
      variant={variant}
      tooltip={tooltip}
      required={required}
      error={meta.touched && meta.error}
      errorText={meta.error}
      helperText={helperText}
      disabled={disabled}
      loading={loading}
      onBlur={onBlur}
      cache={cache}
      fullWidth={fullWidth}
      placeholder={placeholder}
      bannerText={bannerText}
      testId={testId}
    />
  );
};

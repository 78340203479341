import React, { useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';
import { TranslationConfigService } from '@vk-hr-tek/core/translations';

export const TranslationsProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const translationConfig = useInject(TranslationConfigService);

  useEffect(() => {
    translationConfig.init();
  }, [translationConfig]);

  //eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

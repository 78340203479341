import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';

import { CHECK_TOKEN_INTERVAL } from '../../auth.constants';

import { AuthOpenIdService } from './auth.openid.service';
import { AuthOpenIdVerifyService } from './auth.openid.verify.service';

@injectable()
export class AuthTokenService {
  private readonly key = 'authToken';

  private isRestoring = false;

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
    private readonly openid: AuthOpenIdService,
    private readonly openidVerifyService: AuthOpenIdVerifyService,
  ) {}

  save(token: string): void {
    this.localStorage.setItem(this.key, token);
  }

  get(): string | null {
    return this.localStorage.getItem(this.key) || null;
  }

  delete(): void {
    this.localStorage.removeItem(this.key);
  }

  async restore() {
    await this.openid.restore();
    const nextOpenIdToken = this.openid.get();

    if (nextOpenIdToken) {
      const result = await this.openidVerifyService.verify({
        token: nextOpenIdToken,
      });

      if (result.access_token) {
        this.save(result.access_token);
      }
    }
  }

  async restoreToken(): Promise<void> {
    if (this.isRestoring) {
      return new Promise((resolve) => {
        const id = setInterval(() => {
          if (!this.isRestoring) {
            clearInterval(id);
            resolve(undefined);
          }
        }, CHECK_TOKEN_INTERVAL);
      });
    } else {
      this.isRestoring = true;
      await this.restore();
      this.isRestoring = false;
    }
  }
}

import { ValidateType } from './validation.types';

export const compose =
  (validators: ValidateType[]) =>
  (value: Record<string, any> | any, allValues: Record<string, any>) => {
    const result = validators.reduce(
      (error: any, validator?: ValidateType) =>
        error || validator?.(value, allValues),
      undefined,
    );
    return result;
  };

import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsCompanySignTypesRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsCompanySignTypesRouterService
  implements SettingsCompanySignTypesRouter
{
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToSignTypes() {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.unep_types.basename
      }`,
    );
  }

  goToSignTypeEdit(id: string) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.unep_types.basename
      }/${id}/edit`,
    );
  }
}

import { injectable } from 'inversify';

import { GlobeIcon } from '@vk-hr-tek/ui/icons';
import { t } from '@vk-hr-tek/core/translations/t';

import { WidgetPartition } from '@app/gen/dashboard';
import { modules } from '@app/modules';

@injectable()
export class PartitionMapper {
  processPartitions(
    partitions: WidgetPartition[],
    role: 'company' | 'employee',
  ) {
    return partitions.map((partition) =>
      this.processPartition(partition, role),
    );
  }

  private processPartition(
    partition: WidgetPartition,
    role: 'company' | 'employee',
  ) {
    switch (partition.permission) {
      case 'absences':
        return {
          title: t(`modules.Absences.absences.${role}Title`),
          count: partition.counter,
          resource: modules.absences.resource,
          href: `/${role}/${modules.absences.basename}`,
          icon: modules.absences.icon,
        };
      case 'candidates':
        return {
          title: t(`modules.Candidates.candidates.${role}Title`),
          count: partition.counter,
          resource: modules.candidates.resource,
          href: `/${role}/${modules.candidates.basename}`,
          icon: modules.candidates.icon,
        };
      case 'competencies':
        return {
          title: t(`modules.Competencies.competencies.${role}Title`),
          count: partition.counter,
          resource: modules.competencies.resource,
          href: `/${role}/${modules.competencies.basename}`,
          icon: modules.competencies.icon,
        };
      case 'employees':
        return {
          title: t(`modules.Employees.employees.${role}Title`),
          count: partition.counter,
          resource: modules.employees.resource,
          href: `/${role}/${modules.employees.basename}`,
          icon: modules.employees.icon,
        };
      case 'events':
        return {
          title: t(`modules.Events.events.${role}Title`),
          count: partition.counter,
          resource: modules.events.resource,
          href: `/${role}/${modules.events.basename}`,
          icon: modules.events.icon,
        };
      case 'events_to_paper':
        return {
          title: t(`modules.Events.paper.${role}Title`),
          count: partition.counter,
          resource: modules.events.children.paper.resource,
          href: `/${role}/${modules.events.basename}/${modules.events.children.paper.basename}`,
          icon: GlobeIcon,
        };
      case 'organization':
        return {
          title: t(`modules.Organization.organization.${role}Title`),
          count: partition.counter,
          resource: modules.organization.resource,
          href: `/${role}/${modules.organization.basename}`,
          icon: modules.organization.icon,
        };
      case 'payslips':
        return {
          title: t(`modules.Payslips.payslips.${role}Title`),
          count: partition.counter,
          resource: modules.payslips.resource,
          href: `/${role}/${modules.payslips.basename}`,
          icon: modules.payslips.icon,
        };
      case 'personal_profile':
        return {
          title: t(`modules.Personal.personal.${role}Title`),
          count: partition.counter,
          resource: modules.personal.resource,
          href: `/${role}/${modules.personal.basename}`,
          icon: modules.personal.icon,
        };
      case 'policies':
        return {
          title: t(`modules.Policy.policy.${role}Title`),
          count: partition.counter,
          resource: modules.policy.resource,
          href: `/${role}/${modules.policy.basename}`,
          icon: modules.policy.icon,
        };
      case 'settings_attorneys':
        return {
          title: t(`modules.Settings.attorneys.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.attorneys.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.attorneys.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_attorneys_users':
        return {
          title: t(`modules.Settings.attorneys-users.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.attorney_users.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.attorney_users.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_company':
        return {
          title: t(`modules.Settings.company.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.company.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.company.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_event_types':
        return {
          title: t(`modules.Settings.settings.${role}Title`),
          count: partition.counter,
          resource: modules.settings.resource,
          href: `/${role}/${modules.settings.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_groups':
        return {
          title: t(`modules.Settings.groups.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.groups.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.groups.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_substitutes':
        return {
          title: t(`modules.Settings.substitutes.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.substitutes.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.substitutes.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_templates':
        return {
          title: t(`modules.Settings.templates.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.templates.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.templates.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_unep_types':
        return {
          title: t(`modules.Settings.unep-types.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.unep_types.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.unep_types.basename}`,
          icon: modules.settings.icon,
        };
      case 'settings_vacation':
        return {
          title: t(`modules.Settings.vacations.${role}Title`),
          count: partition.counter,
          resource: modules.settings.children.vacations.resource,
          href: `/${role}/${modules.settings.basename}/${modules.settings.children.vacations.basename}`,
          icon: modules.settings.icon,
        };
      case 'vacation':
        return {
          title: t(`modules.Vacations.vacations.${role}Title`),
          count: partition.counter,
          resource: modules.vacations.resource,
          href: `/${role}/${modules.vacations.basename}`,
          icon: modules.vacations.icon,
        };
      default:
        return {
          title: '',
        };
    }
  }
}

import { createSlice } from '@reduxjs/toolkit';

import { initialState, policyAdapter } from './policy.state';
import {
  creationReducers,
  policyListReducers,
  policyDetailReducers,
} from './actions';

export const policy = createSlice({
  name: 'policy',
  initialState: policyAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    policyListReducers(builder);
    creationReducers(builder);
    policyDetailReducers(builder);
  },
});

export const policyReducer = policy.reducer;

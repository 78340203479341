type Events = 'mousemove' | 'scroll' | 'keydown' | 'mousedown' | 'touchstart';

export interface IdleOptions {
  timeout?: number;
  events?: Events[];
  onIdle?: () => void;
  onActive?: () => void;
}

export class IsIdle {
  private settings: Required<IdleOptions>;

  private timer: ReturnType<typeof setTimeout> | null = null;

  private idle = false;

  constructor({
    //eslint-disable-next-line no-magic-numbers
    timeout = 1000 * 60 * 5,
    events = ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'],
    onIdle = () => {},
    onActive = () => {},
  }: IdleOptions = {}) {
    this.settings = {
      timeout,
      events,
      onIdle,
      onActive,
    };
  }

  start() {
    this.timeout();

    this.bulkAddEventListener(this.settings.events, this.idlenessEventsHandler);

    return this;
  }

  stop() {
    this.bulkRemoveEventListener(
      this.settings.events,
      this.idlenessEventsHandler,
    );
    this.resetTimeout(false);

    return this;
  }

  private timeout() {
    this.timer = setTimeout(() => {
      this.idle = true;
      this.settings.onIdle();
    }, this.settings.timeout);
  }

  private idlenessEventsHandler = () => {
    if (this.idle) {
      this.idle = false;
      this.settings.onActive();
    }
    this.resetTimeout();
  };

  private resetTimeout(keepTracking = true) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      if (keepTracking) {
        this.timeout();
      }
    }
  }

  private bulkAddEventListener(events: Events[], callback: () => void) {
    events.forEach((event) => {
      window.addEventListener(event, callback);
    });
  }

  private bulkRemoveEventListener(events: Events[], callback: () => void) {
    events.forEach((event) => {
      window.removeEventListener(event, callback);
    });
  }
}

import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const SidebarPersonalPageIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7.5C9.17157 7.5 8.5 8.17157 8.5 9C8.5 9.82843 9.17157 10.5 10 10.5C10.8284 10.5 11.5 9.82843 11.5 9C11.5 8.17157 10.8284 7.5 10 7.5ZM7 9C7 7.34315 8.34315 6 10 6C11.6569 6 13 7.34315 13 9C13 10.6569 11.6569 12 10 12C8.34315 12 7 10.6569 7 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12777 2C5.51749 2 4.78763 2.1378 4.04631 2.53427C3.39639 2.88185 2.88185 3.39639 2.53427 4.04631C2.1378 4.78763 2 5.51749 2 7.12777V12.8722C2 14.4825 2.1378 15.2124 2.53427 15.9537C2.88185 16.6036 3.39639 17.1182 4.04631 17.4657C4.78763 17.8622 5.51749 18 7.12777 18H12.8722C14.4825 18 15.2124 17.8622 15.9537 17.4657C16.6036 17.1182 17.1182 16.6036 17.4657 15.9537C17.8622 15.2124 18 14.4825 18 12.8722V7.12777C18 5.51749 17.8622 4.78763 17.4657 4.04631C17.1182 3.39639 16.6036 2.88185 15.9537 2.53427C15.2124 2.1378 14.4825 2 12.8722 2H7.12777ZM12.8722 16.5C13.6516 16.5 14.1457 16.4647 14.4975 16.4009C13.3598 15.2279 11.7674 14.5 10.0047 14.5C8.24131 14.5 6.64828 15.2285 5.51048 16.4023C5.86175 16.4652 6.35429 16.5 7.12777 16.5H12.8722ZM4.75371 3.85699C5.16644 3.63625 5.59677 3.5 7.12777 3.5H12.8722C14.4032 3.5 14.8336 3.63625 15.2463 3.85699C15.6348 4.06477 15.9352 4.36519 16.143 4.75371C16.3637 5.16644 16.5 5.59677 16.5 7.12777V12.8722C16.5 14.4032 16.3637 14.8336 16.143 15.2463C16.0614 15.3988 15.9656 15.5378 15.8558 15.6628C14.4347 14.0322 12.3403 13 10.0047 13C7.66656 13 5.57014 14.0345 4.14897 15.6682C4.03714 15.5417 3.93972 15.401 3.85699 15.2463C3.63625 14.8336 3.5 14.4032 3.5 12.8722V7.12777C3.5 5.59677 3.63625 5.16644 3.85699 4.75371C4.06477 4.36519 4.36519 4.06477 4.75371 3.85699ZM7 9C7 7.34315 8.34315 6 10 6C11.6569 6 13 7.34315 13 9C13 10.6569 11.6569 12 10 12C8.34315 12 7 10.6569 7 9ZM10 7.5C9.17157 7.5 8.5 8.17157 8.5 9C8.5 9.82843 9.17157 10.5 10 10.5C10.8284 10.5 11.5 9.82843 11.5 9C11.5 8.17157 10.8284 7.5 10 7.5Z"
      />
    </SvgIcon>
  );
};

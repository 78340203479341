import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

export const VkPointerIcon = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      htmlColor={theme.tokens.colors.text.dark.primary}
      viewBox="0 0 54 56"
      fontSize="inherit"
    >
      <path
        d="M39.625 6.50003L2.875 22.0609L23.3526 31.7013L51.25 2.75L33.0491 53.375L25.1875 36.6875"
        fill={theme.tokens.colors.original.brand.primary}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import { injectable } from 'inversify';

import { QueryService } from '@vk-hr-tek/core/query';

import {
  WidgetTasksBadge,
  WidgetTasksEvent,
  WidgetTasksPolicy,
  WidgetTasksResponse,
  WidgetTasksVacationSchedule,
  Widget,
} from '@app/gen/dashboard';
import { modules } from '@app/modules';

@injectable()
export class DashboardMapper {
  constructor(private readonly query: QueryService) {}

  processBadges(badges: WidgetTasksBadge[]) {
    return badges.map((badge) => ({
      counterBackgroundColor: badge.color as 'blue' | 'red',
      count: badge.value,
      label: badge.title,
    }));
  }

  processUrlParams(
    task: WidgetTasksEvent | WidgetTasksVacationSchedule | WidgetTasksPolicy,
  ) {
    if ('filter' in task && task.filter) {
      return this.query.stringify({
        filters: task.filter,
      });
    }

    return '';
  }

  processSide(
    task: WidgetTasksEvent | WidgetTasksVacationSchedule | WidgetTasksPolicy,
  ) {
    if ('side' in task) {
      return task.side;
    }
  }

  processTaskList(tasks: WidgetTasksResponse['tasks']) {
    return tasks.map((task) => {
      const query = this.processUrlParams(task);

      let module: string = task.partition;
      let resource = modules.events.resource;

      if (task.partition === 'event') {
        module = modules.events.basename;
        resource = modules.events.resource;
      } else if (task.partition === 'policy') {
        module = modules.policy.basename;
        resource = modules.policy.resource;
      } else if (task.partition === 'vacation_schedule') {
        module = modules.vacations.basename;
        resource = modules.vacations.resource;
      }

      return {
        title: task.title,
        page: task.partition,
        query,
        module,
        resource,
        actionTitle: task.partition_title,
        badges: task.badges && this.processBadges(task.badges),
        side: this.processSide(task),
        href: `/${'side' in task ? task.side : 'employee'}/${module}${
          query ? `?${query}` : ''
        }`,
      };
    });
  }

  processLayout(widgets: Widget[], ignore: string[] = []) {
    return widgets
      .filter((item) => item.type !== 'quick_actions' && !item.hidden)
      .filter(
        (item) =>
          item.type !== 'partitions_employee' ||
          !window.REACT_APP_VKHRTEK_LK_DISABLED,
      )
      .filter((item) => !ignore.includes(item.type))
      .map((widget) => ({
        i: widget.type,
        x: widget.position.x,
        y: 0,
        w: widget.position.width as 1 | 2 | 3, // eslint-disable-line no-magic-numbers
        h: widget.position.height || 1,
      }));
  }
}

import React, { forwardRef, useCallback } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { RedirectCard } from '@vk-hr-tek/ui/RedirectCard';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserApiService } from '@vk-hr-tek/app/user/services';
import { selectUser } from '@vk-hr-tek/app/user';

import { useSelector } from '@app/hooks';

interface UserCardProps {
  onClick: () => void;
  userFullName: string;
}

export const UserCard = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ onClick, userFullName }: UserCardProps, ref) => {
    const userApiService = useInject(UserApiService);

    const user = useSelector(selectUser);

    const stringAvatar = (name: string) =>
      `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

    const loadPhoto = useCallback(async () => {
      const res = await userApiService.getUserPhoto({
        user_id: user?.id ?? '',
      });

      return res.file;
    }, [userApiService, user?.id]);

    return (
      <Box
        width={268}
        mt={{ md: '16', xs: '0' }}
        mb={{ md: '8', xs: '0' }}
        mx="16"
      >
        <RedirectCard onClick={onClick}>
          <Avatar loadPhoto={loadPhoto} children={stringAvatar(userFullName)} />

          <EllipsisText>
            <Typography variant="body3">{userFullName}</Typography>
          </EllipsisText>
        </RedirectCard>
      </Box>
    );
  },
);

import React, { useState, useCallback } from 'react';

import { Link } from '../Link';

import { RedoCountdown } from './RedoCountdown';

export const CODE_RESEND_DELAY_MS = 59000;

export interface RedoProps {
  label: string;
  action: () => void;
  after?: number;
  timestamp?: number;
  disabled?: boolean;
}

export const Redo = ({
  label,
  action,
  after = CODE_RESEND_DELAY_MS,
  timestamp,
  disabled = false,
}: RedoProps) => {
  const calcEndDate = (timestamp ?? Date.now()) + after;
  const [endDate, setEndDate] = useState(calcEndDate);
  const [isTimerVisible, setIsTimerVisible] = useState(Date.now() < endDate);

  const toggleTimerVisibility = useCallback(() => {
    setIsTimerVisible((prevShowTimer) => !prevShowTimer);
  }, []);

  const handleSendAgainClick = useCallback(() => {
    setEndDate(Date.now() + after);
    toggleTimerVisibility();
    action();
  }, [after, action, toggleTimerVisibility]);

  return isTimerVisible ? (
    <RedoCountdown
      label={label}
      disabled={disabled}
      finalDate={endDate}
      toggleTimerVisibility={toggleTimerVisibility}
    />
  ) : (
    <Link onClick={handleSendAgainClick} disabled={disabled} stroke={false}>
      {label}
    </Link>
  );
};

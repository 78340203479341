export const REQUIRED_FIELD_VALIDATION_MESSAGE = {
  message: 'Обязательное поле',
};

export const SMS_CODE_LENGTH = 6;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 128;

const ONE_SECOND_IN_MILISECONDS = 1000;
const SECONDS_COUNT = 60;
const MINUTES_COUNT = 10;

export const TEN_MINS =
  ONE_SECOND_IN_MILISECONDS * SECONDS_COUNT * MINUTES_COUNT;
export const CHECK_TOKEN_INTERVAL = 100;
export const WAITING_TIME_AFTER_LOGIN = 1000;

import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent } from '@vk-hr-tek/ui/Dialog';
import { Button } from '@vk-hr-tek/ui/Button';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { useDetectAdBlock } from '@vk-hr-tek/core/hooks';

export const AdblockDetector = () => {
  const adBlockDetected = useDetectAdBlock();

  const [adblockModalState, setAdblockModalState] = useState<boolean>(false);

  const handleAccept = () => {
    setAdblockModalState(false);
  };

  const handleClose = () => setAdblockModalState(false);

  useEffect(() => {
    if (adBlockDetected) {
      setAdblockModalState(adBlockDetected);
    }
  }, [adBlockDetected]);

  return (
    <Dialog open={adblockModalState} onClose={handleClose}>
      <DialogContent
        onClose={handleClose}
        actions={
          <Button onClick={handleAccept} size="large" variant="tertiaryLight">
            Принять
          </Button>
        }
      >
        <Box display="flex" flexDirection="column" gap="16">
          <Typography variant="h6">Уважаемый пользователь!</Typography>
          <Box display="flex" flexDirection="column" gap="8">
            <Typography variant="body3">
              У вас установлено расширение Adblock. Его работа может привести к
              некорректной работе нашего сервиса.
            </Typography>
            <Typography variant="body3">
              Пожалуйста, внесите приложение{' '}
              <b>{window.REACT_APP_VKHRTEK_GENERAL_DOMAIN}</b> в список
              доверенных.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

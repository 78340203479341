import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { Box } from '@vk-hr-tek/ui/Box';
import { useInject } from '@vk-hr-tek/core/ioc';
import { useQuery } from '@vk-hr-tek/core/query';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { TokenService } from '@vk-hr-tek/core/http';
import { Logger } from '@vk-hr-tek/core/logger';

import { ErrorBoundary } from '../error-boundary';
import { AuthRouterService, AuthCandidateIdService } from '../../auth/services';
import { useAuth } from '../../auth';
import vkHrTekLogo from '../../../../../public/logo/vk-hr-tek-logo.svg';
import { CandidateLoginInitDto } from '../../auth/dto/candidate';
import { WithTranslations } from '../WithTranslations';

import { useStyles } from './CandidateLayout.styles';

interface CandidateLayoutProps {
  children: JSX.Element;
}

export const CandidateLayout = ({ children }: CandidateLayoutProps) => {
  const isAuthenticated = useAuth();
  const location = useLocation();
  const authRouter = useInject(AuthRouterService);
  const candidateId = useInject(AuthCandidateIdService);
  const token = useInject<TokenService>(TokenService);
  const logger = useInject(Logger);
  const [{ invite }] = useQuery(CandidateLoginInitDto);
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  const [, setVersion] = useState(0);

  useEffect(() => {
    if (!isAuthenticated && !location.pathname.startsWith('/auth/')) {
      authRouter.redirectToCandidateLogin();
    }

    if (
      isAuthenticated &&
      location.pathname.startsWith('/auth/login') &&
      invite &&
      candidateId.get() &&
      candidateId.get() !== invite
    ) {
      candidateId.delete();
      token.delete();
      setVersion((prevVersion) => prevVersion + 1);
    }
  }, [isAuthenticated, location, invite, authRouter, candidateId, token]);

  if (
    isAuthenticated &&
    location.pathname.startsWith('/auth/login') &&
    invite &&
    candidateId.get() &&
    candidateId.get() !== invite
  ) {
    return null;
  }

  return (
    <ErrorBoundary logger={logger}>
      <div className={isDesktop ? classes.root : classes.rootMobile}>
        {isAuthenticated ? (
          <>
            <header className={classes.header}>
              <Container>
                <Box className={classes.logoContainer}>
                  <img src={vkHrTekLogo} className={classes.logo} />
                </Box>
              </Container>
            </header>
            <Container className={classes.container}>
              <WithTranslations>{children}</WithTranslations>
            </Container>
          </>
        ) : (
          <WithTranslations>{children}</WithTranslations>
        )}
      </div>
    </ErrorBoundary>
  );
};

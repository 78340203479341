import React, { ReactElement } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';

import { scaleBarInputConfigs } from './ScaleBarInput.configs';

const SCALE_BAR_ELEMENTS_COUNT = 5;

const getConfig = (value: number, target: number) => {
  let type: keyof typeof scaleBarInputConfigs;

  if (value < target) type = 'low';
  else if (value === target) type = 'normal';
  else type = 'high';

  return scaleBarInputConfigs[type];
};

interface ScaleBarProps {
  target?: number;
  value?: number;
  showLabel?: boolean;
  renderTooltip?: (index: number) => ReactElement | undefined;
  size?: 'sm' | 'md';
  color?: 'text' | 'stroke';
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export const ScaleBarInput = ({
  target = 0,
  value = 0,
  renderTooltip,
  size = 'md',
  color = 'text',
  onChange,
  showLabel = true,
  disabled,
}: ScaleBarProps) => {
  const config = getConfig(value, target);

  const handleChange = (i: number) => {
    if (disabled) {
      return;
    }
    onChange?.(i);
  };

  return (
    <Box display="flex" flexDirection="column" gap="8" width="100%">
      {showLabel && (
        <Box>
          <Typography variant="caption" color={config.text.color}>
            {config.text.label}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap="4">
        {new Array(SCALE_BAR_ELEMENTS_COUNT).fill(null).map((_, index) => {
          const tooltip = renderTooltip?.(index);
          const colorCell =
            color === 'stroke' ? config.level.strokeColor : config.level.color;

          return (
            <Tooltip
              disabled={!tooltip}
              title={tooltip || ''}
              placement="bottom"
              key={index}
            >
              <Box
                display="flex"
                flexGrow={1}
                height={size === 'md' ? 8 : 4}
                radius="m"
                bgcolor={index < value ? colorCell : 'stroke.primary'}
                onClick={() => handleChange(index + 1)}
                cursor={onChange && !disabled ? 'pointer' : 'default'}
                key={index}
              />
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

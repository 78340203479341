import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { inject, injectable } from 'inversify';

import { LocaleService } from '../http';

@injectable()
export class TranslationConfigService {
  constructor(@inject(LocaleService) private readonly locale: LocaleService) {}

  init() {
    i18n.use(initReactI18next).init({
      resources: {},
      lng: this.locale.get(),
      defaultNS: 'translations',
    });
  }
}

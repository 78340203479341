/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { plainToClass } from 'class-transformer';

import { useInject } from '../ioc';

import { QueryService } from './query.service';

const isNothing = (obj: any) => !obj || obj === '0' || obj === 'false';

/**
 * @example
 * flattenObject({
    a: {
      b: 'b1',
      c: {
        d: 'd1'
      }
    }
  })
 * => {
    'a.b': 'b1',
    'a.c': 'd1',
  }
 *
 * @description The implementation is really fast,
 * it does not create too many intermediate objects
 */
function flattenObject(head: any): Record<string, string> {
  function dfsFlatten(
    node: any,
    path: string[],
    result: Record<string, string>,
  ) {
    for (const key in node) {
      if (!Object.prototype.hasOwnProperty.call(node, key) || isNothing(key))
        continue;

      path.push(key);
      const element = node[key];
      if (typeof element === 'object') {
        dfsFlatten(element, path, result);
      } else {
        // base case
        result[path.join('.')] = element;
      }
      path.pop();
    }
  }

  const result = {} as Record<string, string>;
  dfsFlatten(head, [], result);
  return result;
}

/** Хук, который возвращает массив, где
 * первый элемент - объектное представление query string
 * второй элемент - метод, обновляющий в браузере query string на основе переданного ему объекта
 * */
export function useQuery<T extends { [key: string]: any }>(
  constructor: new () => T,
  router?: { goToList: (url: string) => void },
  namespace?: string,
): [T, (dto: Partial<T>) => void] {
  const history = useHistory();
  const location = useLocation();
  const query = useInject(QueryService);

  const result = query.parse(location.search.split('#')[0]);
  const filteredResult = query.parse(location.search.split('#')[0], namespace);

  const setQuery = (update: Partial<T>): void => {
    if (namespace && update.filters) {
      Object.entries(update.filters).forEach(([key, value]) => {
        if (
          !value ||
          (Array.isArray(value) && !value.length) ||
          value === '0'
        ) {
          Object.keys(result).forEach((resultKey) => {
            if (resultKey.includes(key)) delete result[resultKey];
          });
        }

        if (value && typeof value === 'object') {
          const filterValues = Object.values(value);

          if (
            !filterValues.length ||
            (filterValues.length && filterValues.every((item) => !item))
          ) {
            Object.keys(result).forEach((resultKey) => {
              if (resultKey.includes(key)) delete result[resultKey];
            });
          }
        }
      });
    }

    const nextQuery = {
      ...result,
      ...update,
      ...(update.filters
        ? {
            filters: {
              ...((result && result.filters) || {}),
              ...update.filters,
            },
          }
        : {}),
    };

    for (const key in nextQuery) {
      if (key && !nextQuery[key]) {
        delete nextQuery[key];
      }

      if (
        (key === 'filters' &&
          typeof nextQuery[key] === 'object' &&
          !Array.isArray(nextQuery[key])) ||
        nextQuery[key] === null
      ) {
        for (const filterKey in nextQuery[key]) {
          if (!filterKey) continue;

          if (isNothing(nextQuery[key][filterKey])) {
            delete nextQuery[key][filterKey];
          }

          if (Array.isArray(nextQuery[key][filterKey])) {
            nextQuery[key][filterKey] = nextQuery[key][filterKey].filter(
              (value: any) => value && value !== '0',
            );

            if (!nextQuery[key][filterKey].length) {
              delete nextQuery[key][filterKey];
            }
          } else if (typeof nextQuery[key][filterKey] === 'object') {
            const flattenParams = flattenObject({
              [filterKey]: nextQuery[key][filterKey],
            });
            delete nextQuery[key][filterKey];
            Object.assign(nextQuery[key], flattenParams);
          }
        }
      }
    }

    if (router?.goToList) {
      router.goToList(query.stringify(nextQuery, namespace));
    } else {
      history.push({
        ...location,
        search: query.stringify(nextQuery, namespace),
      });
    }
  };

  const classInstance = useMemo(
    () => plainToClass(constructor, namespace ? filteredResult : result),
    [location.search],
  );

  return [classInstance, setQuery];
}

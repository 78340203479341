/* eslint-disable no-magic-numbers */
import DateFnsUtilsBase from '@date-io/date-fns';

export class DateFnsUtils extends DateFnsUtilsBase {
  public getCalendarHeaderText(date: Date) {
    let month = '';

    switch (date.getMonth()) {
      case 0:
        month = 'Январь';
        break;
      case 1:
        month = 'Февраль';
        break;
      case 2:
        month = 'Март';
        break;
      case 3:
        month = 'Апрель';
        break;
      case 4:
        month = 'Май';
        break;
      case 5:
        month = 'Июнь';
        break;
      case 6:
        month = 'Июль';
        break;
      case 7:
        month = 'Август';
        break;
      case 8:
        month = 'Сентябрь';
        break;
      case 9:
        month = 'Октябрь';
        break;
      case 10:
        month = 'Ноябрь';
        break;
      case 11:
        month = 'Декабрь';
        break;
    }
    return `${month} ${this.format(date, 'yyyy')}`;
  }
}

import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import {
  BurgerIcon,
  CloseIcon,
  SidebarHomePageIcon,
} from '@vk-hr-tek/ui/icons';

import { useSelector } from '@app/hooks';
import { ROLES } from '@app/constants';
import { UserRoleEnum } from '@app/types';

import {
  selectStatus,
  selectUserRepresentative,
  selectHasCompanySide,
} from '../../../user';
import { Logo } from '../Logo';
import { AbilityProvider } from '../../AbilityProvider';
import { SidebarItem } from '../Sidebar';
import { SidebarMenu } from '../Sidebar/SidebarMenu';

export const MobileMenu = () => {
  const { pathname } = useLocation();

  const userPermisionsStatus = useSelector(selectStatus);
  const userRepresentative = useSelector(selectUserRepresentative);
  const hasCompanySide = useSelector(selectHasCompanySide);

  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

  const isSkeletonLoading = userPermisionsStatus === 'loading';

  const handleSetDrawerOpened = (state: boolean) => () => {
    setDrawerOpened(state);
  };

  const handleIsActive = (path: string, href: string): boolean => {
    if (href === '/') {
      return path === href || path.includes('/events');
    } else if (href === '/settings') {
      return (
        path === href ||
        path.includes('/event-types') ||
        path.includes('/settings')
      );
    } else {
      return path.startsWith(href) || path === href;
    }
  };

  const filteredRoles = ROLES().filter((item) => {
    if (window.REACT_APP_VKHRTEK_LK_DISABLED && userRepresentative) {
      return item.role === UserRoleEnum.Company;
    }

    if (!hasCompanySide) {
      return item.role === UserRoleEnum.Employee;
    }

    return item;
  });

  useEffect(() => {
    if (drawerOpened) {
      handleSetDrawerOpened(false)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box>
      <Button
        size="medium"
        variant="tertiaryBlue"
        onClick={handleSetDrawerOpened(true)}
        className="aqa_mobileMenuBurger"
        icon={<BurgerIcon />}
      />
      <Drawer
        open={drawerOpened}
        onClose={handleSetDrawerOpened(false)}
        anchor="left"
      >
        <Box
          maxWidth={324}
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <Box mx="16" mt="8" mb="16">
            <Box
              mb="20"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                size="medium"
                variant="tertiaryBlue"
                className="aqa_mobileMenuClose"
                onClick={handleSetDrawerOpened(false)}
                icon={<CloseIcon />}
              />

              <Box
                display="flex"
                alignItems="center"
                className="aqa_mobileMenuLogo"
              >
                <Box mr="12" display="flex" alignItems="center">
                  <Logo variant="24" />
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="16"
              overflow="auto"
              pb="32"
              className="aqa_mobileMenuLinks"
            >
              <SidebarItem
                href="/"
                icon={<SidebarHomePageIcon />}
                aqaClass="aqa_dashboard"
                title="Главная"
                sidebarOpened
                isActive={handleIsActive(pathname, '/')}
                skeletonLoading={isSkeletonLoading}
              />

              {filteredRoles.map(({ role, title }) => (
                <AbilityProvider key={role} role={role}>
                  <SidebarMenu
                    key={role}
                    menuLabel={title}
                    skeletonLoading={isSkeletonLoading}
                    userRole={role}
                    sidebarOpened
                  />
                </AbilityProvider>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

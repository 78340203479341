import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { Typography } from '@vk-hr-tek/ui/Typography';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { useInject } from '@vk-hr-tek/core/ioc';
import { QrScanner } from '@vk-hr-tek/core/qr';
import { Box } from '@vk-hr-tek/ui/Box';

import { Page } from '../../../ui/page';
import { VerifyPassCodeDto } from '../../../dto';

export const Qr = () => {
  const validator = useInject(ValidationService);
  const history = useHistory();

  const handleScan = useCallback(
    async (passCode: string) => {
      const errors = await validator.validate({ passCode }, VerifyPassCodeDto, [
        'load',
      ]);

      if (!Object.keys(errors).length) {
        history.push(`/auth/pass?code=${passCode}`);
      }
    },
    [validator, history],
  );

  return (
    <Page title="Сканирование QR-кода" theme="ali">
      <Typography align="center">
        Для входа отсканируйте QR-код вашего пропуска
      </Typography>
      <Box pt="32" display="flex" justifyContent="center">
        <QrScanner onScan={handleScan} />
      </Box>
    </Page>
  );
};

import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { RedirectPage } from '../../types';
import { TEN_MINS } from '../../auth.constants';

@injectable()
export class AuthRouterService {
  private readonly key = 'redirectPage';

  private readonly savedPageLifetime = TEN_MINS;

  constructor(
    @inject(History) private history: History,
    @inject(RedirectPage)
    private readonly redirectPageService: RedirectPage,
  ) {}

  redirectToHome() {
    this.history.push('/');
  }

  redirectToLogin() {
    this.saveRedirectPage();

    this.history.push({
      pathname: '/auth/login',
      search: '',
    });
  }

  redirectToAliLogin() {
    this.history.push({
      pathname: '/auth/ali',
      search: '',
    });
  }

  redirectToCandidateLogin() {
    this.history.push({
      pathname: '/auth/candidate',
      search: '',
    });
  }

  redirectToQr() {
    this.history.push({
      pathname: '/auth/qr',
      search: '',
    });
  }

  redirectToRegister() {
    this.history.push({
      pathname: '/auth/register',
      search: '',
    });
  }

  redirectToPasswordChange() {
    this.history.push({
      pathname: '/auth/change-password',
      search: '',
    });
  }

  redirectToPhoneChange() {
    this.history.push({
      pathname: '/auth/register',
      search: '?target=phone_change',
    });
  }

  redirectToSendInvite() {
    this.history.push({
      pathname: '/auth/send-invite',
      search: '',
    });
  }

  redirectToSendInviteForPhone() {
    this.history.push({
      pathname: '/auth/send-invite',
      search: '?target=phone_change',
    });
  }

  redirectToSaved() {
    try {
      const { pathname, search, hash, timestamp } = JSON.parse(
        this.redirectPageService.get(),
      );

      if (
        typeof pathname !== 'string' ||
        typeof search !== 'string' ||
        typeof hash !== 'string' ||
        typeof timestamp !== 'number'
      ) {
        throw new Error('Invalid saved page data');
      }

      if (Date.now() - timestamp > this.savedPageLifetime) {
        throw new Error('Redirect page expired.');
      }

      this.redirectPageService.delete();
      this.history.push({ pathname, search, hash });
    } catch (err) {
      this.redirectPageService.delete();
      this.redirectToHome();
    }
  }

  saveRedirectPage(): void {
    if (!this.history.location.pathname.startsWith('/auth/')) {
      this.redirectPageService.save(
        JSON.stringify({
          pathname: this.history.location.pathname,
          search: this.history.location.search || '',
          hash: this.history.location.hash || '',
          timestamp: Date.now(),
        }),
      );
    }
  }

  isVKUser(): boolean {
    const redirectPage = this.redirectPageService.get();

    try {
      return !!(
        redirectPage &&
        JSON.parse(redirectPage).search &&
        JSON.parse(redirectPage).search.match('vk=1')
      );
    } catch (err) {
      return false;
    }
  }
}

import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  button: {
    boxShadow: 'none !important',
    fontSize: theme.tokens.typography.variant.body2.fontSize,
    padding: theme.tokens.spacing['16'],
    height: theme.px(40),
    borderRadius: theme.tokens.radius.m,
    '&.MuiButton-sizeLarge': {
      fontSize: theme.tokens.typography.variant.body1.fontSize,
      padding: theme.tokens.spacing['24'],
      height: theme.px(56),
      borderRadius: theme.tokens.radius.l,
    },
    '&.MuiButton-sizeSmall': {
      fontSize: theme.tokens.typography.variant.body3.fontSize,
      height: theme.px(32),
    },
    '&.MuiButton-text': {
      background: 'none',
      color: theme.tokens.colors.text.light.primary,
      '&:hover': {
        color: theme.tokens.colors.original.brand.primary,
      },
    },
    '&.Mui-disabled': {
      border: 'none',
      backgroundColor: theme.tokens.colors.bg.greyscale.primary,
      color: theme.tokens.colors.text.light.tertirary,
      '&.MuiButton-text': {
        background: 'none',
      },
    },
  },
  buttonPrimary: {
    '&:hover': {
      '&.buttonLoading': {
        backgroundColor: theme.tokens.colors.original.brand.primary,
      },
    },
  },
  buttonSecondary: {
    borderWidth: theme.tokens.border.m,
    padding: theme.tokens.spacing['12'],
    borderColor: theme.tokens.colors.original.brand.primary,
    '&:hover': {
      borderWidth: theme.tokens.border.m,
      '&.buttonLoading': {
        backgroundColor: theme.tokens.colors.bg.light.primary,
        borderColor: theme.tokens.colors.original.brand.primary,
      },
    },
  },
  buttonQuaternary: {
    backgroundColor: theme.tokens.colors.stroke.primary,
    color: theme.tokens.colors.text.light.primary,
    '&:hover': {
      backgroundColor: theme.tokens.colors.original.brand.secondary,
      '&.buttonLoading': {
        backgroundColor: theme.tokens.colors.stroke.primary,
      },
    },
  },
  buttonQuaternaryDark: {
    backgroundColor: theme.tokens.colors.bg.light.primary,
    color: theme.tokens.colors.original.brand.primary,
    '&:hover': {
      backgroundColor: theme.tokens.colors.bg.greyscale.tertirary,
      color: theme.tokens.colors.original.brand.primary,
      '&.buttonLoading': {
        backgroundColor: theme.tokens.colors.bg.light.primary,
        color: theme.tokens.colors.original.brand.primary,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.tokens.colors.bg.light.primary,
      color: theme.tokens.colors.text.light.tertirary,
    },
  },
  tertiaryDark: {
    '&.MuiButton-text:not(:hover)': {
      color: 'white !important',
    },
  },
  tertiaryLight: {
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: 'inherit',
      },
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: 'inherit',
      },
    },
  },
  tertiaryBlue: {
    '&.MuiButton-text': {
      color: `${theme.tokens.colors.original.brand.primary}!important`,
      '&:hover': {
        backgroundColor: theme.tokens.colors.bg.greyscale.primary,
      },
    },
  },
  buttonWarning: {
    backgroundColor: theme.tokens.colors.accent.text.error,
    color: theme.tokens.colors.text.dark.primary,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      '&.buttonLoading': {
        backgroundColor: theme.tokens.colors.accent.text.error,
      },
    },
  },
  buttonIconOnly: {
    minWidth: 'unset',
    width: theme.px(40),
    padding: theme.tokens.spacing['16'],
    '& .MuiButton-endIcon': {
      margin: 0,
      '& > *:first-child': {
        fontSize: theme.tokens.typography.variant.h5.fontSize,
      },
    },
    '&.MuiButton-sizeLarge': {
      width: theme.px(56),
      padding: theme.tokens.spacing['16'],
    },
    '& .MuiButton-endIcon.MuiButton-iconSizeSmall > *:first-child': {
      fontSize: theme.tokens.typography.variant.body2.fontSize,
    },
    '&.MuiButton-sizeSmall': {
      width: theme.px(32),
      padding: theme.tokens.spacing['8'],
    },
    '&.MuiButton-fullWidth': {
      width: '100%',
    },
  },
  buttonPrimaryIconOnly: {
    '&.MuiButton-sizeLarge:not(.Mui-disabled)': {
      backgroundColor: theme.tokens.colors.bg.greyscale.primary,
      color: theme.tokens.colors.original.brand.primary,
      '&:hover:not(.buttonLoading)': {
        backgroundColor: theme.tokens.colors.bg.greyscale.tertirary,
        color: theme.tokens.colors.original.brand.tertirary,
      },
    },
  },
  buttonLoading: {
    '& .MuiButton-endIcon': {
      visibility: 'hidden',
    },
    '&.MuiButton-text': {
      color: theme.tokens.colors.original.brand.primary,
    },
  },
  buttonExtraSmall: {
    borderRadius: theme.tokens.radius.s,
    '&.MuiButton-sizeSmall': {
      padding: theme.tokens.spacing['4'],
      height: theme.px(24),
      width: theme.px(24),
    },
    '&.MuiButton-fullWidth': {
      width: '100%',
    },
  },
  loading: {
    position: 'absolute',
  },
}));

export default styles;

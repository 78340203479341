import React from 'react';

import { FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

interface WhenProps<T> {
  field: string;
  isIn: T[];
  reset: string;
  operation: 'in' | 'ex';
}

export const WhenFieldChanges = <T extends unknown>({
  field,
  isIn,
  reset,
  operation,
}: WhenProps<T>) => (
  <FormSpy>
    {({ form }) => (
      <OnChange name={field}>
        {(value) => {
          const errors = form.getState().errors || {};
          const isIncludes = isIn.includes(value);

          if (
            (operation === 'in' ? isIncludes : !isIncludes) &&
            errors[reset]
          ) {
            form.change(reset, undefined);
            form.resetFieldState(reset);
          }
        }}
      </OnChange>
    )}
  </FormSpy>
);

import React, { useCallback, useState } from 'react';

import {
  Add as AddIcon,
  ClearSharp as ClearSharpIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import { Form } from '@vk-hr-tek/ui/form';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { useTranslations } from '@vk-hr-tek/core/hooks';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { useDispatch } from '@app/hooks';

import { RemoveSubstituteDto } from '../../../dto';
import { CompanyPositionSubstitutes, removeSubstitute } from '../../../slice';
import { UserRouter } from '../../../types';

interface SubstitutesProps {
  hasMultiEmployees: boolean;
  managableUnit: CompanyPositionSubstitutes;
}

export const Substitutes = ({
  hasMultiEmployees,
  managableUnit,
}: SubstitutesProps) => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const router = useInject<UserRouter>(UserRouter);
  const { t } = useTranslations();

  const [removeDialogIsOpened, setRemoveDialogIsOpened] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [employeeCompanyName, setEmployeeCompanyName] = useState('');
  const [substituteId, setSubstituteId] = useState('');
  const [substituteName, setSubstituteName] = useState('');
  const [eventTypeId, setEventTypeId] = useState('');

  const handleClose = () => {
    setEmployeeId('');
    setEmployeeCompanyName('');
    setSubstituteId('');
    setSubstituteName('');
    setEventTypeId('');
    setRemoveDialogIsOpened(false);
  };

  const handleOpen =
    (
      selectEmployeeId: string,
      selectEmployeeCompanyName: string,
      selectedUserId: string,
      selectedUserName: string,
      selectedEventTypeId: string,
    ) =>
    () => {
      setEmployeeId(selectEmployeeId);
      setEmployeeCompanyName(selectEmployeeCompanyName);
      setSubstituteId(selectedUserId);
      setSubstituteName(selectedUserName);
      setEventTypeId(selectedEventTypeId);
      setRemoveDialogIsOpened(true);
    };

  const onSubmit = (values: RemoveSubstituteDto) =>
    new Promise((resolve) => {
      const closeDialog = (value: unknown) => {
        resolve(value);
        handleClose();
      };

      dispatch(
        removeSubstitute({
          values: {
            employeeId,
            substituteId,
            eventTypeId,
          },
          actions: {
            resolve: () => {
              closeDialog(values);
            },
            reject: closeDialog,
          },
        }),
      );
    });

  const handleSubstitutes = useCallback(
    (employeeIdParam: string, companyIdParam: string) => () => {
      router.goToProfileSubstitutes({
        employeeId: employeeIdParam,
        companyId: companyIdParam,
      });
    },
    [router],
  );

  if (!managableUnit.eventTypeGroup) {
    return null;
  }

  return (
    <Box>
      {!isDesktop && !Boolean(managableUnit.eventTypeGroup.length) ? (
        <Box display="flex" flexDirection="column" gap="16" p="24">
          <Typography variant={'subtitle1'}>
            {managableUnit.company.name}
          </Typography>
          <Typography variant="body3" color="text.light.tertirary">
            У вас пока нет заместителей
          </Typography>
        </Box>
      ) : (
        <Box m="24" pb="0">
          <Box
            key={`${managableUnit.company.name}${managableUnit.employee.position}`}
            className="aqa_profile_substitutes"
          >
            <Grid container spacing="16">
              <Grid item xs={12} md={3}>
                <Box
                  mb={isDesktop ? '0' : '16'}
                  mr={isDesktop ? '24' : '0'}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="calc(100% - 24px)"
                >
                  <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
                    {managableUnit.company.name}
                  </Typography>
                  {hasMultiEmployees && (
                    <Typography variant={'body2'} color="text.light.tertirary">
                      {`${managableUnit.employee.position}, ${managableUnit.employee.personalNumber}`}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box mb={isDesktop ? '16' : '0'}>
                  {isDesktop && (
                    <Button
                      icon={<AddIcon color="primary" fontSize="medium" />}
                      onClick={handleSubstitutes(
                        managableUnit.employee.id,
                        managableUnit.company.id,
                      )}
                      size="large"
                    />
                  )}
                </Box>
              </Grid>
              {managableUnit.eventTypeGroup
                .filter(
                  ({ substitutes: eventTypeSubstitutes }) =>
                    eventTypeSubstitutes.length,
                )
                .map(
                  (
                    { eventType, substitutes: eventTypeSubstitutes },
                    index,
                    arr,
                  ) => (
                    <>
                      <Grid item xs={12} md={3}>
                        <Box
                          alignItems="center"
                          display="flex"
                          height={isDesktop ? '56' : 'auto'}
                        >
                          <EllipsisText rows={3} wordBreak="break-word">
                            <Typography
                              variant={isDesktop ? 'body2' : 'body3'}
                              color={
                                isDesktop
                                  ? 'text.light.secondary'
                                  : 'text.light.tertirary'
                              }
                            >
                              {eventType.name}
                            </Typography>
                          </EllipsisText>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Box>
                          <Grid container spacing={isDesktop ? '16' : '8'}>
                            {eventTypeSubstitutes.map(
                              ({ id, name, personalNumber }) => (
                                // eslint-disable-next-line no-magic-numbers
                                <Grid item xs={isDesktop ? 6 : 12} key={id}>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="start"
                                    height="100%"
                                    width="100%"
                                    px="16"
                                    py="8"
                                    bgcolor="bg.greyscale.primary"
                                    radius="l"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="start"
                                      width="100%"
                                    >
                                      <Box>
                                        <Box fontSize={14} mb="4">
                                          <EllipsisText rows={1}>
                                            <Typography variant="body3">
                                              {name}
                                            </Typography>
                                          </EllipsisText>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            color="text.light.tertirary"
                                            mr="8"
                                            fontSize={16}
                                          >
                                            <ListAltIcon fontSize="inherit" />
                                          </Box>
                                          <EllipsisText
                                            rows={1}
                                            wordBreak="break-all"
                                          >
                                            <Typography
                                              variant="caption"
                                              color="text.light.tertirary"
                                            >
                                              {personalNumber}
                                            </Typography>
                                          </EllipsisText>
                                        </Box>
                                      </Box>
                                      {isDesktop && (
                                        <Link
                                          size="large"
                                          onClick={handleOpen(
                                            managableUnit.employee.id,
                                            managableUnit.company.name,
                                            id,
                                            name,
                                            eventType.id,
                                          )}
                                          startIcon={
                                            <ClearSharpIcon color="action" />
                                          }
                                          stroke={false}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                      {arr.length - 1 !== index && <Divider />}
                    </>
                  ),
                )}
            </Grid>
          </Box>

          <Dialog onClose={handleClose} open={removeDialogIsOpened}>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    p="48"
                  >
                    <Box pb="24">
                      <Typography variant="h5">Удаление заместителя</Typography>
                    </Box>
                    <Box>
                      <Box pb="16">
                        <Typography color="text.light.tertirary">
                          {t(
                            'user.profile.substitutes.employeeDeleteConfirmationText',
                          )}
                        </Typography>
                      </Box>
                      <Box p="16" radius="l" bgcolor="bg.greyscale.primary">
                        <Grid container spacing="8">
                          <Grid item xs={4}>
                            <Typography color="text.light.tertirary">
                              Юрлицо:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {employeeCompanyName}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography color="text.light.tertirary">
                              Заместитель:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {substituteName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    p="24"
                    display="flex"
                    justifyContent="end"
                    bgcolor="bg.greyscale.primary"
                  >
                    <Box mr="12">
                      <Button
                        disabled={submitting}
                        onClick={handleClose}
                        size="large"
                        variant="tertiaryLight"
                      >
                        Назад
                      </Button>
                    </Box>
                    <Box ml="12">
                      <Button loading={submitting} size="large" type="submit">
                        Удалить
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

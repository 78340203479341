import React, { useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AppsIcon from '@material-ui/icons/Apps';

import { Box } from '../../Box';
import { Tooltip } from '../../Tooltip';

import { useStyles } from './SelectedOptionsInput.styles';

type Option = {
  label: string;
  value: string;
  tooltip?: {
    title: string;
    subtitle?: string;
  };
  prefix?: string;
  error?: boolean;
};

interface SelectedOptionsInputProps {
  options: Option[];
  onDelete?: (option: Option) => void;
  renderTooltip?: (option: Option) => React.ReactElement;
  limit?: number;
  maxWidth?: number;
  withTooltip?: boolean;
}

export const SelectedOptionsInput = ({
  options,
  onDelete,
  limit = 15,
  renderTooltip,
  withTooltip,
  maxWidth,
}: SelectedOptionsInputProps) => {
  const classes = useStyles({ maxWidth });
  const [page, setPage] = useState(1);

  const shownOptions = options.slice(0, limit * page);

  return (
    <div>
      <Box display="flex" flexWrap="wrap">
        {shownOptions.map((option) => (
          <Box m="4" key={option.value} color="accent.text.error">
            <Tooltip
              disabled={!withTooltip}
              title={renderTooltip ? renderTooltip(option) : option.label}
              placement="top"
            >
              <Chip
                classes={{
                  root: classes.chipInput,
                  deleteIcon: option.error ? classes.iconError : '',
                }}
                label={`${option.prefix ? `${option.prefix}, ` : ''}${
                  option.label
                }`}
                onDelete={() => {
                  if (!onDelete) {
                    return;
                  }

                  onDelete(option);
                }}
                deleteIcon={onDelete ? <ClearIcon /> : undefined}
                className={option.error ? classes.chipError : classes.chip}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
      <Box display="flex">
        {options.length > shownOptions.length && (
          <Box m="4">
            <Box
              className={classes.button}
              gap="8"
              onClick={() => setPage((currentPage) => currentPage + 1)}
            >
              Показать еще <MoreHorizIcon />
            </Box>
          </Box>
        )}
        {options.length > shownOptions.length && (
          <Box m="4">
            <Box
              className={classes.button}
              gap="8"
              onClick={() => setPage(Math.ceil(options.length / limit))}
            >
              Показать все {options.length} <AppsIcon />
            </Box>
          </Box>
        )}
        {options.length <= shownOptions.length && options.length > limit && (
          <Box m="4">
            <Box className={classes.button} onClick={() => setPage(1)}>
              Скрыть
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

import { useContext } from 'react';

import { interfaces } from 'inversify';

import { IocContext } from './IocContext';

export function useInject<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const container = useContext(IocContext);

  if (!container) {
    throw new Error('Ioc container is empty');
  }

  return container.get<T>(identifier);
}

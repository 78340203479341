/* istanbul ignore file */
import { injectable } from 'inversify';

@injectable()
export class AuthOpenIdService {
  get(): string | null {
    return null;
  }

  async restore() {
    // This is a placeholder method. Do nothing
  }
}

import React from 'react';

import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export const AwardIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fontSize="inherit"
      {...props}
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H36C42.6274 0 48 5.37258 48 12V36C48 42.6274 42.6274 48 36 48H12C5.37258 48 0 42.6274 0 36V12Z"
        fill={theme.tokens.colors.bg.greyscale.primary}
      />
      <path
        d="M27.477 24.89L28.992 33.416C29.009 33.5164 28.9949 33.6196 28.9516 33.7118C28.9084 33.8039 28.838 33.8807 28.7499 33.9318C28.6619 33.9829 28.5603 34.0059 28.4588 33.9977C28.3573 33.9895 28.2607 33.9506 28.182 33.886L24.602 31.199C24.4292 31.0699 24.2192 31.0001 24.0035 31.0001C23.7878 31.0001 23.5778 31.0699 23.405 31.199L19.819 33.885C19.7403 33.9494 19.6439 33.9883 19.5425 33.9965C19.4411 34.0047 19.3397 33.9818 19.2517 33.9309C19.1637 33.8799 19.0933 33.8033 19.0499 33.7113C19.0066 33.6193 18.9923 33.5163 19.009 33.416L20.523 24.89M30 20C30 23.3137 27.3137 26 24 26C20.6863 26 18 23.3137 18 20C18 16.6863 20.6863 14 24 14C27.3137 14 30 16.6863 30 20Z"
        stroke={theme.tokens.colors.stroke.tertirary}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={theme.tokens.colors.bg.greyscale.primary}
      />
    </SvgIcon>
  );
};

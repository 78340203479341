import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class AbsenceCompanyItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;
}

export class AbsenceCompanyOptionsList {
  @ValidateNested({ each: true })
  @Type(() => AbsenceCompanyItem)
  @IsArray()
  @IsNotEmpty()
  items: AbsenceCompanyItem[];
}

export class FilterSelectOptionBadge {
  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['blue', 'velvet', 'red'])
  @IsString()
  @IsOptional()
  color?: 'blue' | 'velvet' | 'red';

  @IsString()
  @IsOptional()
  title?: string;
}

export class FilterSelectOption {
  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => FilterSelectOptionBadge)
  @IsOptional()
  badge?: FilterSelectOptionBadge;
}

export type AbsenceTypeOptionsResponse = FilterSelectOption[];

export class FilterMultiple {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple';

  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: FilterSelectOption[];

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;
}

export class FilterMultipleSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple-search'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple-search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class AvailableAbsenceFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (FilterMultipleSearch | FilterMultiple)[];
}

export type UnitManagerSettingApprove = string;

export class CompanyEventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class UnitManagerSetting {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @IsIn(['always', 'direct_only', 'never'])
  @IsString()
  @IsNotEmpty()
  approve: 'always' | 'direct_only' | 'never';
}

export type SignType = string;

export class CompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export class CompanyEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';
}

export class Unit {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsOptional()
  parent_id?: string;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsOptional()
  manager?: CompanyEmployee;

  @ValidateNested({ each: true })
  @Type(() => UnitManagerSetting)
  @IsArray()
  @IsOptional()
  manager_settings?: UnitManagerSetting[];
}

export type AbsenceStatus = string;

export class AbsenceItem {
  @IsString()
  @IsNotEmpty()
  type: string;

  @IsIn([
    'new',
    'event_created',
    'approved',
    'in_progress',
    'completed',
    'canceled',
  ])
  @IsString()
  @IsNotEmpty()
  status:
    | 'new'
    | 'event_created'
    | 'approved'
    | 'in_progress'
    | 'completed'
    | 'canceled';

  @IsDateString()
  @IsNotEmpty()
  from_date: string;

  @IsDateString()
  @IsNotEmpty()
  to_date: string;

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsUUID()
  @IsOptional()
  event_id?: string;

  @IsBoolean()
  @IsNotEmpty()
  is_scheduled_vacation: boolean;

  @IsUUID()
  @IsOptional()
  event_type_id?: string;
}

export type DateType = string;

export class WorkCalendarDate {
  @IsDateString()
  @IsNotEmpty()
  date: string;

  @IsIn(['workday', 'weekend', 'holiday'])
  @IsString()
  @IsNotEmpty()
  type: 'workday' | 'weekend' | 'holiday';
}

export class AbsenceEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsOptional()
  position?: string;

  @ValidateNested()
  @Type(() => Unit)
  @IsOptional()
  unit?: Unit;

  @ValidateNested({ each: true })
  @Type(() => WorkCalendarDate)
  @IsArray()
  @IsNotEmpty()
  calendar_diff: WorkCalendarDate[];
}

export class AbsenceListGroup {
  @ValidateNested()
  @Type(() => AbsenceEmployee)
  @IsNotEmpty()
  employee: AbsenceEmployee;

  @ValidateNested({ each: true })
  @Type(() => AbsenceItem)
  @IsArray()
  @IsNotEmpty()
  absences: AbsenceItem[];

  @ValidateNested({ each: true })
  @Type(() => Unit)
  @IsArray()
  @IsOptional()
  units?: Unit[];
}

export class AbsenceListResponse {
  @ValidateNested({ each: true })
  @Type(() => AbsenceListGroup)
  @IsArray()
  @IsNotEmpty()
  groups: AbsenceListGroup[];

  @IsInt()
  @IsNotEmpty()
  total: number;
}

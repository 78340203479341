import React from 'react';

import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const ContentCopyIcon = ({
  size = 'normal',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6663 1.16699H2.66634C1.93301 1.16699 1.33301 1.76699 1.33301 2.50033V11.8337H2.66634V2.50033H10.6663V1.16699ZM12.6663 3.83366H5.33301C4.59967 3.83366 3.99967 4.43366 3.99967 5.16699V14.5003C3.99967 15.2337 4.59967 15.8337 5.33301 15.8337H12.6663C13.3997 15.8337 13.9997 15.2337 13.9997 14.5003V5.16699C13.9997 4.43366 13.3997 3.83366 12.6663 3.83366ZM12.6663 14.5003H5.33301V5.16699H12.6663V14.5003Z"
      />
    </SvgIcon>
  );
};

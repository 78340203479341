import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { Information } from '@vk-hr-tek/app/user/ui/information';
import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { ContentCopyIcon } from '@vk-hr-tek/ui/icons';

import { AuthEmployee, ProfileItem } from '@app/gen/users';
import { AttorneyType, SignTypes } from '@app/types';

export const EmployeeInfo = ({
  employee,
}: {
  employee: AuthEmployee & {
    groups: {
      id: string;
      name: string;
      roles: {
        id: number;
        name: string;
        profiles?: ProfileItem[];
      }[];
      attorneys: AttorneyType[];
    };
  };
}) => {
  const isDesktop = useIsDesktop();

  const tooltipPlacement = isDesktop ? 'right' : 'bottom';
  const textVariant = isDesktop ? 'body2' : 'body3';

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={isDesktop ? '16' : '12'}
      paddingX="24"
      paddingY="16"
    >
      <Information
        label={
          <Typography
            variant={isDesktop ? 'h6' : 'subtitle1'}
            color="text.light.primary"
          >
            {employee.company.name}
          </Typography>
        }
        spacing="24"
      >
        <Box pt={{ xs: '4', md: '0' }}>
          <ColoredChip
            label={
              employee.dismissed_at
                ? `Дата увольнения ${employee.dismissed_at}`
                : 'Работаю'
            }
            color={employee.dismissed_at ? 'gray' : 'green'}
          />
        </Box>
      </Information>

      <Information
        label={
          <Typography variant={textVariant} color="text.light.tertirary">
            Табельный №
          </Typography>
        }
        spacing="24"
      >
        <Box
          gap="8"
          display="flex"
          alignItems="center"
          pt={{ xs: '4', md: '0' }}
        >
          <Typography variant={textVariant}>
            {employee.personnel_number}
          </Typography>
          <IconButton
            type="button"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(employee.personnel_number);
            }}
          >
            <ContentCopyIcon
              color="primary"
              size={isDesktop ? 'default' : 'small'}
            />
          </IconButton>
        </Box>
      </Information>

      {employee.manager && (
        <Information
          label={
            <Typography variant={textVariant} color="text.light.tertirary">
              Руководитель
            </Typography>
          }
          spacing="24"
        >
          <Typography variant={textVariant}>{employee.manager.name}</Typography>
        </Information>
      )}

      <Information
        label={
          <Typography variant={textVariant} color="text.light.tertirary">
            Используется УНЭП
          </Typography>
        }
        spacing="24"
      >
        <Box
          gap="8"
          display="flex"
          alignItems="center"
          pt={{ xs: '4', md: '0' }}
        >
          <Typography variant={textVariant}>
            {SignTypes[employee.sign_type]}
          </Typography>
          <Tooltip
            title={
              employee.sign_type === 'cryptopro_local'
                ? 'Усиленная неквалифицированная электронная подпись на локальном цифровом носителе'
                : 'Установлен по умолчанию для подписания документов в заявках'
            }
            placement={tooltipPlacement}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <HelpIcon color="disabled" fontSize="small" />
            </Box>
          </Tooltip>
        </Box>
      </Information>
    </Box>
  );
};

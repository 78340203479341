import { injectable } from 'inversify';
import i18n from 'i18next';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { TranslationsDto } from '../dto';

@injectable()
export class TranslationsService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getTranslations() {
    const translations = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/translations`,
        {},
        {
          withSide: true,
        },
      ),
      TranslationsDto,
    );

    return translations;
  }

  async addTranslations(lang: string, translations: TranslationsDto) {
    i18n.addResourceBundle(lang, 'translations', translations);
    await i18n.loadNamespaces('translations');
  }
}

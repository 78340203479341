import React from 'react';

import { Container } from 'inversify';

export const IocContext = React.createContext<Container | null>(null);

type IocProviderProps = {
  container: Container;
  children: React.ReactNode | React.ReactNode[];
};

export const IocProvider = ({ container, children }: IocProviderProps) => {
  return (
    <IocContext.Provider value={container}>{children}</IocContext.Provider>
  );
};

import { injectable } from 'inversify';

import { Filter } from '@vk-hr-tek/core/filter';

@injectable()
export class AbsencesListMapper {
  /* eslint-disable no-magic-numbers */
  processListFilters(filters: Filter[]) {
    if (!filters) return null;

    return filters.map((filter) => {
      if (filter.type === 'multiple-search' && filter.key === 'employee_ids') {
        return {
          ...filter,
          twoLine: true,
          minInputValueLength: 3,
          fullWidth: false,
        };
      }

      if (filter.type === 'search' && filter.key === 'parent_event_id') {
        return {
          ...filter,
          width: 12 as const,
          disabled: true,
        };
      }

      if (filter.type === 'multiple-search' && filter.key === 'unit_ids') {
        return {
          ...filter,
          fullWidth: true,
          width: 12 as const,
        };
      }

      if (filter.type === 'select' && filter.key === 'event_batch') {
        return {
          ...filter,
          autocomplete: true,
        };
      }

      if (filter.type === 'select' && filter.key === 'event_type_group') {
        return {
          ...filter,
          options: [...filter.options].sort((a, b) =>
            (a.label || '').toLowerCase() > (b.label || '').toLowerCase()
              ? 1
              : -1,
          ),
          autocomplete: true,
        };
      }

      return {
        ...filter,
        fullWidth: false,
      };
    });
  }
}

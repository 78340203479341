import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const ExpandMoreIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 24 24"
    >
      <path d="M6.68162 9.28658C6.09428 8.82732 5.24584 8.93115 4.78658 9.51849C4.32731 10.1058 4.43115 10.9543 5.01849 11.4135L11.1635 16.2184C11.6519 16.6003 12.3376 16.6005 12.8262 16.2188L18.9761 11.4139C19.5636 10.9548 19.6678 10.1064 19.2088 9.51891C18.7497 8.93139 17.9013 8.82722 17.3138 9.28625L11.9954 13.4415L6.68162 9.28658Z" />
    </SvgIcon>
  );
};

export interface SettingsRouter {
  goToMainSettings(): void;

  goToCompanyStructure(id: string): void;

  goToCompanyProfilesEdit(id: string): void;

  goBack(prevState: { pathname: string; search: string }): void;

  goToCompanySettings(): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsRouter = Symbol('SettingsRouter');

import React from 'react';

export const AppGalleryIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2476 0H6.75235C1.80861 0 0 1.80861 0 6.75236V17.2476C0 22.1914 1.80861 24 6.75235 24H17.2447C22.1884 24 24 22.1914 24 17.2476V6.75236C24 1.80861 22.1914 0 17.2476 0Z"
      fill="#C8102E"
    />
    <path
      d="M4.75148 12.0015H5.38738V15.1396H4.75148V13.8648H3.31405V15.1396H2.67668V12.0015H3.31405V13.2674H4.75148V12.0015ZM8.33913 13.7983C8.33913 14.3085 8.08625 14.5806 7.62634 14.5806C7.16346 14.5806 6.9091 14.2996 6.9091 13.7761V12.003H6.27172V13.7968C6.27172 14.6796 6.7627 15.1854 7.61746 15.1854C8.4811 15.1854 8.97651 14.6693 8.97651 13.7702V12H8.34061V13.7983H8.33913ZM15.6963 14.1591L14.9835 12.0015H14.4645L13.7517 14.1591L13.0581 12.003H12.3808L13.4751 15.1396H14.0016L14.7159 13.0795L15.4287 15.1396H15.9596L17.0509 12.003H16.3914L15.6963 14.1591ZM18.1926 13.7894H19.3505V13.2186H18.1926V12.5767H19.874V12.0044H17.5656V15.1381H19.9347V14.5658H18.1926V13.7894ZM20.6933 15.1351H21.3218V12.0015H20.6933V15.1351ZM10.2335 14.4844L9.9481 15.1381H9.29742L10.6801 12.0015H11.2421L12.6189 15.1381H11.9519L11.6695 14.4844H10.2335ZM10.4701 13.9195H11.4329L10.9508 12.7971L10.4701 13.9195Z"
      fill="white"
    />
    <path
      d="M11.9993 8.00753C9.79582 8.00753 8.00495 6.21518 8.00495 4.01172H8.56987C8.56987 5.90315 10.1093 7.44261 12.0008 7.44261C13.8922 7.44261 15.4317 5.90315 15.4317 4.01172H15.9966C15.9951 6.21518 14.2027 8.00753 11.9993 8.00753Z"
      fill="white"
    />
  </svg>
);

import React, { useState } from 'react';

import {
  Print as PrintIcon,
  SystemUpdateAlt as SystemUpdateAltIcon,
} from '@material-ui/icons';

import { useIsDesktop } from '../../../hooks';
import { Box } from '../../../Box';
import { ButtonGroup } from '../../../ButtonGroup';
import { Button } from '../../../Button';

import { DownloadButton } from './DownloadButton';

interface PreviewButtonsProps {
  pdf?: boolean;
  withCollapsableFooter?: boolean;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onClickPrint?: () => void;
}

export const PreviewButtons = ({
  pdf,
  withCollapsableFooter,
  onClickDownload,
  onClickPrint,
}: PreviewButtonsProps) => {
  const isDesktop = useIsDesktop();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const handleDownloadClick = async () => {
    if (pdf) {
      setIsLoading(true);
      setError(null);
    }

    if (!onClickDownload) {
      return;
    }

    const handlerRes = await onClickDownload();
    if (typeof handlerRes === 'object' && 'file' in handlerRes) {
      handlerRes.file.then(
        (blob) => {
          const newObjectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = newObjectUrl;
          link.download = handlerRes.filename || 'document';
          link.target = '_blank';
          link.click();
          link.remove();
          URL.revokeObjectURL(newObjectUrl);

          if (pdf) {
            setIsLoading(false);
          }
        },
        (e) => {
          if (pdf) {
            setError(e);
            setIsLoading(false);
          }
        },
      );
    } else {
      try {
        const newObjectUrl = URL.createObjectURL(handlerRes);
        const link = document.createElement('a');
        link.href = newObjectUrl;
        link.download = 'documents.zip';
        link.target = '_blank';
        link.click();
        link.remove();
        URL.revokeObjectURL(newObjectUrl);

        if (pdf) {
          setIsLoading(false);
        }
      } catch (e) {
        if (pdf) {
          setError(e);
          setIsLoading(false);
        }
      }
    }
  };

  const handlePrintClick = async () => {
    if (!pdf || !onClickPrint) return;

    setIsPrinting(true);

    try {
      await onClickPrint();
    } finally {
      setIsPrinting(false);
    }
  };

  if (
    ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
    navigator.userAgent.match('CriOS')
  ) {
    return null;
  }

  const buttonGroup =
    pdf && onClickDownload && onClickPrint && !error ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="20"
      >
        <ButtonGroup
          size={isDesktop ? 'large' : 'medium'}
          orientation={isDesktop ? 'horizontal' : 'vertical'}
          separated={!isDesktop}
        >
          {isDesktop ? (
            <Button
              icon={<PrintIcon />}
              onClick={handlePrintClick}
              loading={isPrinting}
              disabled={isPrinting}
            />
          ) : (
            <div></div>
          )}
          <Button
            icon={<SystemUpdateAltIcon />}
            loading={isLoading}
            disabled={isLoading}
            onClick={handleDownloadClick}
          />
        </ButtonGroup>
      </Box>
    ) : null;

  return withCollapsableFooter ? (
    <DownloadButton onClick={handleDownloadClick} />
  ) : (
    buttonGroup
  );
};

import { AppError } from '@vk-hr-tek/core/error';

export interface TranslationsState {
  loading: 'idle' | 'loading' | 'complete' | 'failed';
  error: AppError | null;
}

export const initialState: TranslationsState = {
  loading: 'idle',
  error: null,
};

import React from 'react';

import { Form as FinalForm, FormProps } from 'react-final-form';

import { setFieldData } from './form.mutators';

export const Form = <
  FormValues extends Record<string, any>,
  InitialFormValues = Partial<FormValues>,
>(
  props: FormProps<FormValues, InitialFormValues>,
) => <FinalForm {...props} mutators={{ ...props.mutators, setFieldData }} />;

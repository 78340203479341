import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { ValidationService } from '@vk-hr-tek/core/validation';

import {
  CalendarBirthdaysResponse,
  CalendarWidgetResponse,
} from '@app/gen/dashboard';

import { GetBirthdaysDto, GetCalendarDto } from '../dto';

@injectable()
export class CalendarService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private validator: ValidationService,
  ) {}

  async getCalendar(getCalendarDto: GetCalendarDto) {
    await this.validator.validateOrReject(getCalendarDto, GetCalendarDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/widget/calendar/${getCalendarDto.year}`,
        {},
        { withSide: true },
      ),
      CalendarWidgetResponse,
    );

    return result;
  }

  async getBirthdays(getBirthdaysDto: GetBirthdaysDto) {
    await this.validator.validateOrReject(getBirthdaysDto, GetBirthdaysDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/widget/calendar/birthdays',
        { employee_ids: getBirthdaysDto.employee_ids },
        { withSide: true },
      ),
      CalendarBirthdaysResponse,
    );

    return result;
  }
}

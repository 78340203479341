import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { modules } from '../../modules';
import { PersonalRouter } from '../../../personal/types';

@injectable()
export class PersonalRouterService implements PersonalRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${this.role.get()}/${modules.personal.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.personal.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToWorkBook(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${
        modules.personal.basename
      }/workbook?company_id=${companyId}`,
    );
  }

  goToEducation(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${
        modules.personal.basename
      }/education?company_id=${companyId}`,
    );
  }

  goToEmploymentContract(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${
        modules.personal.basename
      }/employment-contract?company_id=${companyId}`,
    );
  }

  goToWorkHistory(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${
        modules.personal.basename
      }/workhistory?company_id=${companyId}`,
    );
  }

  goToAwardsPage(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${
        modules.personal.basename
      }/awards?company_id=${companyId}`,
    );
  }
}

import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';

import { RootState } from '@app/store';
import { UserRoleEnum } from '@app/types';

import { UserMapper } from '../mappers';

export const selectStatus = (state: RootState) => state.user.status;

export const selectCertificateStatus = (state: RootState) =>
  state.user.data?.certificate.status ?? null;

export const selectCertificateSignType = (state: RootState) =>
  state.user.data?.certificate.sign_type;

export const selectCertificateReleaseMethod = (state: RootState) => {
  return state.user.data ? state.user.data.certificate.release_method : null;
};

export const selectFullName = (state: RootState) => {
  const userData = state.user.data;

  if (userData === null) {
    return null;
  }

  return `${userData.last_name} ${userData.first_name} ${userData.middle_name}`;
};

export const selectUserRepresentative = (state: RootState) =>
  state.user.data?.representative || false;

export const selectPhoneNumber = (state: RootState) =>
  state.user.data?.phone ?? null;

export const selectHasCompanySide = (state: RootState) =>
  state.user.data?.has_company_side ?? null;

export const selectUser = createSelector(
  [
    (state: RootState) => state.user.data,
    (_: RootState, inject: Inject) => (inject as unknown as Inject)(UserMapper),
  ],
  (user, format) => (user ? format.process(user) : null),
);

export const selectEditPermission = (state: RootState) =>
  state.user.data?.employees.some(
    (employee) => employee.permissions.edit_substitutes,
  );

export const selectCompanyViewAbsencesPermission = (state: RootState) =>
  state.user.permissions.company.view_absences;

export const selectEmployeeViewAbsencesPermission = (state: RootState) =>
  state.user.permissions.employee.view_absences;

export const selectManagebleUnits = (state: RootState) =>
  state.user.substitutes.items.map((item) => {
    const employeesLength = state.user.data?.employees.filter(
      (employee) => employee.company.id === item.company.id,
    )?.length;

    return {
      ...item,
      hasMultiEmployees: employeesLength ? employeesLength > 1 : false,
    };
  });

export const selectManageableUnitsByCompanyId = createSelector(
  [
    (state: RootState) => state.user.substitutes.items,
    (_: RootState, inject: Inject, id: string | undefined) => id,
  ],
  (items, companyId) => {
    return items?.find(({ company }) => companyId === company.id)
      ?.manageableUnits;
  },
);

export const selectHasCompanyUnits = (state: RootState) =>
  !!state.user.substitutes.items.filter(
    ({ manageableUnits }) => !!manageableUnits?.length,
  ).length;

export const selectCandidateUser = (state: RootState) =>
  state.user.candidateUser.entity;

export const selectCandidateUserStatus = (state: RootState) =>
  state.user.candidateUser.status;

export const selectDisableLKInfo = (state: RootState) =>
  state.user.data?.non_representative || null;

export const selectError = (state: RootState) => state.user.error;

export const selectTelegramSubscriptionStatus = (state: RootState) =>
  state.user.subscriptions.telegram;

export const selectToken1CStatus = (state: RootState) =>
  state.user.token1C.status;

export const selectToken1C = (state: RootState) => state.user.token1C.value;

export const selectIsAdmin = createSelector(
  (state: RootState) => state.user.data,
  (user) => !!user && user.groups.some((group) => group.is_admin),
);

export const selectToken1CExpiresAt = (state: RootState, inject?: Inject) => {
  const format = (inject as unknown as Inject)(UserMapper);

  return state.user.token1C.expiresAt
    ? format.processToken1CExpiresAtDate(state.user.token1C.expiresAt)
    : '';
};

export const selectToken1CExpiresAtStatus = (state: RootState) =>
  state.user.token1C.expiresStatus;

export const selectPermissions = createSelector(
  [
    (state: RootState) => state.user.permissions,
    (_: RootState, inject: Inject, role: UserRoleEnum) => role,
  ],
  (permissions, role) => {
    return permissions[role];
  },
);

export const selectCompanyEventTypes = (state: RootState) =>
  state.user.companyEventTypes.items;
export const selectCompanyEventTypesStatus = (state: RootState) =>
  state.user.companyEventTypes.status;
export const selectCompanyEventTypesError = (state: RootState) =>
  state.user.companyEventTypes.error;

export const selectSubstitutes = (state: RootState) =>
  state.user.substitutes.items;

export const selectUnitStatus = (state: RootState) => state.user.unit.status;

export const selectUnitError = (state: RootState) => state.user.unit.error;

export const selectUnit = (state: RootState) => state.user.unit.entity;

export const selectUserGroups = (state: RootState) =>
  state.user.data?.groups || [];
export const selectUserEmployees = (state: RootState) =>
  state.user.data?.employees || [];

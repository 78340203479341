import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { VacationScheduleMyDeadlineResponse } from '@vk-hr-tek/app/app/gen/vacations';
import { ValidationService } from '@vk-hr-tek/core/validation';

import {
  CreateEventResponse as CreateResponse,
  CreateEventCompanyOptionsList as OptionsCompanyResponse,
  CreateEventOptionsList,
  CreateEventResponse,
} from '@app/gen/events';
import { AbsenceListResponse } from '@app/gen/absences';

import { GetAbsenceListServiceDto } from '../dto';
import { GetDeadlineDto } from '../dto/get-deadline.dto';
import {
  CreateEventFromAbsenceDto,
  CreateEventFromButtonDto,
} from '../dto/create-event.dto';

@injectable()
export class AbsencesService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private validator: ValidationService,
  ) {}

  async getAbsenceList(dto: GetAbsenceListServiceDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/absences/employee/list',
        {
          filters: {
            company_id: dto.companyId,
            year: dto.year,
          },
        },
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result.groups;
  }

  async getCompanies() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/companies',
        {},
        {
          withSide: true,
        },
      ),
      OptionsCompanyResponse,
    );

    return result.items;
  }

  async getVacationScheduleDeadline(dto: GetDeadlineDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/vacation/schedule/${dto.companyId}/my_deadline`,
        {},
        { withSide: true },
      ),
      VacationScheduleMyDeadlineResponse,
    );

    return result;
  }

  async createEventFromAbsence({
    eventTypeId,
    employeeId,
    attributes,
  }: CreateEventFromAbsenceDto) {
    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: eventTypeId,
          employee_id: employeeId,
          attributes,
        },
        { withSide: true },
      ),
      CreateEventResponse,
    );

    return {
      event_id: response.event_id,
    };
  }

  async getCreateOptions() {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options`,
        {
          tag: ['отсутствие'],
        },
        { withSide: true },
      ),
      CreateEventOptionsList,
    );
  }

  async createEvent(createEventDto: CreateEventFromButtonDto) {
    await this.validator.validateOrReject(
      createEventDto,
      CreateEventFromButtonDto,
      ['submit'],
    );

    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: createEventDto.eventTypeId,
          ...(createEventDto.employeeId !== 'no_employee'
            ? {
                employee_id: createEventDto.employeeId,
              }
            : {}),
          ...(createEventDto.assigned_roles && {
            assigned_roles: createEventDto.assigned_roles,
          }),
          ...(createEventDto.eventTypeId && {
            event_type_id: createEventDto.eventTypeId,
          }),
          ...(createEventDto.parentEventId && {
            parent_event_id: createEventDto.parentEventId,
          }),
        },
        { withSide: true },
      ),
      CreateResponse,
    );

    return {
      event_id: response.event_id,
    };
  }
}

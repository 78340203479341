import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';
import {
  CalendarMapper,
  DashboardMapper,
  FastActionsMapper,
  PartitionMapper,
} from '@vk-hr-tek/app/dashboard/mappers';

import { RootState } from '@app/store';

export const selectDashboardStatus = (state: RootState) =>
  state.dashboard.status;

export const selectDashboardFastActionsStatus = (state: RootState) =>
  state.dashboard.fastActions.status;

export const selectDashboardError = (state: RootState) => state.dashboard.error;

export const selectDashboardLayout = createSelector(
  [
    (state: RootState) => state.dashboard.layout,
    (state: RootState) => !!state.user.data?.has_company_side,
    (_: RootState, inject: Inject) => inject(DashboardMapper),
  ],
  (widgets, hasCompanySide, format) =>
    format.processLayout(widgets, hasCompanySide ? [] : ['functional']),
);

export const selectDashboardFastActions = createSelector(
  [
    (state: RootState) => state.dashboard.fastActions.actions,
    (state: RootState) => !!state.user.data?.has_company_side,
    (_: RootState, inject: Inject) => inject(FastActionsMapper),
  ],
  (fastActions, hasCompanySide, format) =>
    format
      .processFastActions(fastActions)
      .filter((task) => hasCompanySide || task.side === 'employee')
      .filter(
        (task) =>
          task.side === 'company' || !window.REACT_APP_VKHRTEK_LK_DISABLED,
      ),
);

export const selectElectronicServicesStatus = (state: RootState) =>
  state.dashboard.electronicServices.status;

export const selectElectronicServices = (state: RootState) =>
  state.dashboard.electronicServices.items;

export const selectTaskList = createSelector(
  [
    (state: RootState) => state.dashboard.taskList.items,
    (state: RootState) => !!state.user.data?.has_company_side,
    (_: RootState, inject: Inject) => inject(DashboardMapper),
  ],
  (taskList, hasCompanySide, format) =>
    format
      .processTaskList(taskList)
      .filter((task) => hasCompanySide || task.side === 'employee')
      .filter(
        (task) =>
          task.side === 'company' || !window.REACT_APP_VKHRTEK_LK_DISABLED,
      ),
);

export const selectTaskListStatus = (state: RootState) =>
  state.dashboard.taskList.status;

export const selectFastActionData = (state: RootState) =>
  state.dashboard.fastActionData;

export const selectPartitionsEmployeeStatus = (state: RootState) =>
  state.dashboard.partitionsEmployee.status;

export const selectPartitionsEmployee = createSelector(
  [
    (state: RootState) => state.dashboard.partitionsEmployee.items,
    (_: RootState, inject: Inject) => inject(PartitionMapper),
  ],
  (partitions, format) => format.processPartitions(partitions, 'employee'),
);

export const selectPartitionsCompanyStatus = (state: RootState) =>
  state.dashboard.partitionsCompany.status;

export const selectPartitionsCompany = createSelector(
  [
    (state: RootState) => state.dashboard.partitionsCompany.items,
    (_: RootState, inject: Inject) => inject(PartitionMapper),
  ],
  (partitions, format) => format.processPartitions(partitions, 'company'),
);

export const selectFullName = (state: RootState) => {
  const userData = state.user.data;

  if (userData === null) {
    return null;
  }

  return `${userData.first_name} ${userData.middle_name}`;
};

export const selectCalendarStatus = (state: RootState) =>
  state.dashboard.calendar.status;

export const selectCalendarVacationDays = createSelector(
  [
    (state: RootState) => state.dashboard.calendar.companies,
    (state: RootState) => state.dashboard.calendar.companyId,
    (_: RootState, inject: Inject) => inject(CalendarMapper),
  ],
  (companies, companyId, mapper) =>
    mapper.processVacationDays(companies.find(({ id }) => id === companyId)),
);

export const selectCalendarBirthdays = createSelector(
  [
    (state: RootState) => state.dashboard.calendar.birthdays,
    (_: RootState, inject: Inject) => inject(CalendarMapper),
  ],
  (birthdays, mapper) => mapper.processBirthdays(birthdays),
);

export const selectCalendarCompanies = createSelector(
  [
    (state: RootState) => state.dashboard.calendar.companies,
    (_: RootState, inject: Inject) => inject(CalendarMapper),
  ],
  (companies, mapper) => mapper.processCompanies(companies),
);

export const selectCalendarCompanyId = (state: RootState) =>
  state.dashboard.calendar.companyId;

export const selectCalendarCompanyById = createSelector(
  [
    (state: RootState) => state.dashboard.calendar.companies,
    (state: RootState) => state.dashboard.calendar.companyId,
    (state: RootState) => state.dashboard.calendar.includedEmployees,
    (_: RootState, inject: Inject) => inject(CalendarMapper),
  ],
  (companies, companyId, includedEmployees, mapper) =>
    mapper.processCompany(
      companies.find((company) => company.id === companyId),
      includedEmployees,
    ),
);

export const selectCalendarIncludedEmployees = (state: RootState) =>
  state.dashboard.calendar.includedEmployees;

export const selectIsQuickActionsAvailable = createSelector(
  [(state: RootState) => state.dashboard.layout],
  (widgets) => {
    return widgets.some(
      (widget) => widget.type === 'quick_actions' && !widget.hidden,
    );
  },
);

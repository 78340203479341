import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';

import { PolicyEmployee as Employee, PolicyVersion } from '@app/gen/policy';

@injectable()
export class PolicyDetailMapper {
  constructor(private format: FormatService) {}

  processVersions(versions: PolicyVersion[], id: string) {
    let title = 'Будущая';
    let descriptionPlaceholder = 'Будет активна с';
    const now = new Date();

    const sortedVersions = [...versions].sort((first, second) => {
      return new Date(first.active_from) > new Date(second.active_from)
        ? -1
        : 1;
    });

    const activeVersion = this.findActiveVersion(sortedVersions);

    return sortedVersions.map(
      ({
        policy_version_id,
        active_from,
        active_to,
        accepted_employees,
        total_employees,
        document_date,
        document_number,
        signature_type,
        with_order,
      }) => {
        if (
          activeVersion === policy_version_id &&
          new Date(active_from) <= new Date()
        ) {
          title = 'Активная';
          descriptionPlaceholder = 'Активна с';
        }

        if (
          title === 'Активная' &&
          active_to &&
          new Date(active_to) < new Date()
        ) {
          title = 'Прошлая';
          descriptionPlaceholder = 'Была активна с';
        }

        const result = {
          id: policy_version_id,
          fileUrl: `/policy/${id}/version/${policy_version_id}/file`,
          title,
          isEditable: !active_to || new Date(active_to) > now,
          description: `${descriptionPlaceholder} ${this.format.toDate(
            active_from,
          )} ${active_to ? ` по ${this.format.toDate(active_to)}` : ''}`,
          totalEmployees: total_employees,
          acceptedEmployees: accepted_employees || 0,
          active_from,
          active_to,
          document_date,
          document_number,
          signatureType: signature_type && {
            value: signature_type,
            label: this.processSignatureType(signature_type),
          },
          withOrder: with_order,
        };

        if (activeVersion === policy_version_id) {
          title = 'Прошлая';
          descriptionPlaceholder = 'Была активна с';
        }

        return result;
      },
    );
  }

  findActiveVersion(versions: PolicyVersion[]) {
    const sortedVersions = [...versions].sort((first, second) => {
      return new Date(first.active_from) > new Date(second.active_from)
        ? 1
        : -1;
    });

    const now = new Date();
    let activeVersion: string | null = sortedVersions.length
      ? sortedVersions[0].policy_version_id
      : null;
    let activeDate = sortedVersions.length
      ? new Date(sortedVersions[0].active_from)
      : new Date(0);

    sortedVersions.forEach((version) => {
      const versionDate = new Date(version.active_from);
      if (versionDate > activeDate && versionDate < now) {
        activeVersion = version.policy_version_id;
        activeDate = versionDate;
      }
    });

    return activeVersion || '';
  }

  processSignatureType(
    signatureType: 'pep' | 'unep' | 'unep_disabled' | 'ukep',
  ) {
    switch (signatureType) {
      case 'pep':
        return 'ПЭП';
      case 'unep':
      case 'ukep':
        return 'УНЭП/УКЭП';
      default:
        return signatureType;
    }
  }

  processEmployees(employees: Employee[]) {
    return employees.map((employee) => {
      return {
        ...employee,
        dismissedAt: employee.dismissed_at
          ? this.format.toDate(employee.dismissed_at)
          : '',
      };
    });
  }
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens }) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: '54px',
    '& $endIcon': {
      fontSize: tokens.typography.variant.overline.fontSize,
    },
  },
  endIcon: {},
}));

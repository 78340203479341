import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Redo } from '@vk-hr-tek/ui/Redo';
import { Link } from '@vk-hr-tek/ui/Link';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormButton,
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';

import { useSelector } from '@app/hooks';

import {
  candidateLogin,
  candidateLoginInit,
  redirectToCandidateLogin,
  selectInviteCode,
  selectNotification,
  selectPhone,
  selectStatus,
} from '../../../slice';
import {
  CandidateLoginFinishDto,
  CandidateLoginInitDto,
} from '../../../dto/candidate';

export const LoginFinish = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);
  const isDesktop = useIsDesktop();

  const phone = useSelector(selectPhone);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const invite = useSelector(selectInviteCode) ?? '';

  const onSubmit = (values: CandidateLoginFinishDto) => {
    return new Promise((resolve) => {
      dispatch(
        candidateLogin({
          candidateLoginFinishDto: {
            ...values,
            invite,
            phone,
          } as CandidateLoginFinishDto,
          actions: { resolve, reject: resolve },
        }),
      );
    });
  };

  const resendCode = () => {
    return new Promise((resolve) => {
      dispatch(
        candidateLoginInit({
          candidateLoginInitDto: {
            phone,
            invite,
          } as CandidateLoginInitDto,
          actions: { resolve, reject: resolve },
        }),
      );
    });
  };

  const selectAnotherPhone = useCallback(
    () => dispatch(redirectToCandidateLogin(invite)),
    [dispatch, invite],
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) =>
        validator.validate(values, CandidateLoginFinishDto, ['form'])
      }
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormKnownValues>
            <FormKnownValue label="Номер телефона" value={phone} />
          </FormKnownValues>
          <Box mt="24">
            <TextInput
              name="code"
              label="СМС-код"
              placeholder="Введите СМС-код"
              type="number"
              inputMode="numeric"
              autocomplete="one-time-code"
              id="qaAuthLoginFinishCodeInput"
            />
          </Box>
          <Redo label="Отправить код повторно" action={resendCode} />
          <Box mt="24">
            <FormButton
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Войти в HR Tek
            </FormButton>
          </Box>
          {!dirtySinceLastSubmit && status && status === 'failed' && (
            <FormError error={error} />
          )}
          <Box
            display="flex"
            mt={{ xs: '24', md: '8' }}
            justifyContent={!isDesktop && 'center'}
          >
            <Link onClick={selectAnotherPhone} size="small" variant="tertiary">
              Другой номер телефона
            </Link>
          </Box>
        </form>
      )}
    />
  );
};

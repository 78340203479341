import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const SidebarVacationsPageIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3357 11.2358C18.6252 7.5234 16.2597 4.27658 12.6186 2.99704L12.6509 2.8652C12.7428 2.49045 12.5134 2.11203 12.1385 2.02023C11.7637 1.92843 11.3854 2.15768 11.2935 2.53268L11.2703 2.62741C7.47754 1.86935 3.7804 3.46261 2.1398 6.81222C1.7247 7.65988 2.28366 8.59079 3.16226 8.83078L9.14011 10.4636L8.55154 12.758L7.55524 16.8469H4.7363C4.32483 16.8469 3.99127 17.1804 3.99127 17.5918C3.99127 18.0034 4.32483 18.3369 4.7363 18.3369H13.5026C13.9141 18.3369 14.2476 18.0034 14.2476 17.5918C14.2476 17.1804 13.9141 16.8469 13.5026 16.8469H9.08884L9.99615 13.1233L10.5776 10.8562L16.4291 12.4543C17.3077 12.6943 18.2623 12.1767 18.3357 11.2358ZM7.71533 8.6257L12.6085 9.96212C13.5731 7.43429 12.4587 5.22091 11.5607 4.17257C10.2544 4.61886 8.16962 5.95846 7.71533 8.6257ZM9.21283 3.84005C6.68515 3.90426 4.4884 5.21701 3.40649 7.40353C3.42456 7.42819 3.46439 7.46457 3.53049 7.48263L6.36145 8.25583C6.75809 6.1467 7.97223 4.72604 9.21283 3.84005ZM16.7973 11.1062L13.9623 10.3318C14.6932 8.31295 14.3692 6.47165 13.7507 5.0776C15.8978 6.41769 17.1242 8.66647 16.9444 11.101C16.9162 11.1132 16.8634 11.1242 16.7973 11.1062Z"
      />
    </SvgIcon>
  );
};

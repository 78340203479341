import {
  IsNotEmpty,
  IsNumberString,
  IsPhoneNumber,
  IsString,
  IsUUID,
  Length,
} from 'class-validator';

import { SMS_CODE_LENGTH } from '../../auth.constants';

export class CandidateLoginFinishDto {
  @IsPhoneNumber('RU', {
    groups: ['submit'],
  })
  @IsString({
    groups: ['submit'],
  })
  @IsNotEmpty({
    groups: ['submit'],
  })
  phone: string;

  @Length(SMS_CODE_LENGTH, SMS_CODE_LENGTH, {
    message: 'Неверный СМС-код',
    groups: ['form', 'submit'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['form', 'submit'],
    },
  )
  @IsNotEmpty({
    message: 'Вы забыли ввести СМС-код',
    groups: ['form', 'submit'],
  })
  @IsString({
    message: 'Вы забыли ввести СМС-код',
    groups: ['form', 'submit'],
  })
  code: string;

  @IsUUID(undefined, { groups: ['submit'] })
  @IsNotEmpty({ groups: ['submit'] })
  invite: string;
}

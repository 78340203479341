export type SignType =
  | 'disabled'
  | 'goskey'
  | 'kontur'
  | 'cryptopro_simple'
  | 'cryptopro_local'
  | 'ukep';

export enum SignTypes {
  disabled = 'Без ЭЦП',
  goskey = 'Госключ',
  kontur = 'СКБ Контур',
  cryptopro_simple = 'КриптоПро',
  cryptopro_local = 'Цифровой носитель',
  ukep = 'Цифровой носитель',
}

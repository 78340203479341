import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const SidebarCompetenciesPageIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2C10.2908 2 8.49998 3.79086 8.49998 6C8.49998 8.20914 10.2908 10 12.5 10C14.7091 10 16.5 8.20914 16.5 6C16.5 3.79086 14.7091 2 12.5 2ZM9.99998 6C9.99998 7.38071 11.1193 8.5 12.5 8.5C13.8807 8.5 15 7.38071 15 6C15 4.61929 13.8807 3.5 12.5 3.5C11.1193 3.5 9.99998 4.61929 9.99998 6Z"
      />
      <path d="M7.03185 6.46967C7.32474 6.76257 7.32473 7.23744 7.03184 7.53033L3.5318 11.0303C3.23891 11.3232 2.76403 11.3232 2.47114 11.0303L0.469669 9.02884C0.176776 8.73595 0.176777 8.26107 0.469671 7.96818C0.762565 7.67529 1.23744 7.67529 1.53033 7.96818L3.00148 9.43933L5.97119 6.46967C6.26408 6.17677 6.73895 6.17678 7.03185 6.46967Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 10.75C10.7259 10.75 9.12061 11.0333 7.95287 11.6516C6.78334 12.2709 6 13.2605 6 14.6875C6 15.376 6.26777 15.9052 6.68651 16.251C7.09454 16.588 7.64276 16.75 8.24074 16.75H16.7593C17.3572 16.75 17.9055 16.588 18.3135 16.251C18.7322 15.9052 19 15.376 19 14.6875C19 13.2605 18.2167 12.2709 17.0471 11.6516C15.8794 11.0333 14.2741 10.75 12.5 10.75ZM8.65478 12.9773C9.51977 12.5192 10.8492 12.25 12.5 12.25C14.1508 12.25 15.4802 12.5192 16.3452 12.9773C17.1394 13.3977 17.5 13.9358 17.5 14.6875C17.5 14.832 17.4736 14.9216 17.4492 14.9749C17.4252 15.0276 17.3942 15.0648 17.3584 15.0944C17.281 15.1582 17.0984 15.25 16.7593 15.25H8.24074C7.90161 15.25 7.71897 15.1582 7.64165 15.0944C7.6058 15.0648 7.57484 15.0276 7.5508 14.9749C7.52645 14.9216 7.5 14.832 7.5 14.6875C7.5 13.9358 7.86064 13.3977 8.65478 12.9773Z"
      />
    </SvgIcon>
  );
};

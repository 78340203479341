import React from 'react';

import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DocumentIcon = ({
  size = 'normal',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H13.7574C14.0226 4 14.2769 4.10536 14.4645 4.29289L17.7071 7.53553C17.8946 7.72307 18 7.97742 18 8.24264V19ZM4 5C4 3.34315 5.34315 2 7 2H13.7574C14.553 2 15.3161 2.31607 15.8787 2.87868L19.1213 6.12132C19.6839 6.68393 20 7.44699 20 8.24264V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V5ZM9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H9ZM8 11C8 10.4477 8.44772 10 9 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H9C8.44772 12 8 11.5523 8 11ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H9Z"
      />
    </SvgIcon>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { MultipleFileInput as Input } from '../../input';

interface MultipleFileInputProps {
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  name: string;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
}

export const MultipleFileInput = ({
  label,
  tooltip,
  required,
  name,
  validate,
  disabled = false,
  cache,
}: MultipleFileInputProps) => {
  const validators = compose(validate || []);

  const { input, meta } = useField(name, {
    validate: validators,
  });

  const { onChange, onBlur } = input;

  return (
    <Input
      {...input}
      value={input.value}
      label={label}
      disabled={disabled}
      required={required}
      tooltip={tooltip}
      onChange={onChange}
      onBlur={onBlur}
      cache={cache}
      error={meta.touched && meta.error}
      helperText={meta.error}
    />
  );
};

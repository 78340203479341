import React from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormError, TextInput, Form } from '@vk-hr-tek/ui/form';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useDispatch, useSelector } from '@app/hooks';

import {
  registerInitOpenId,
  selectAuthInstance,
  selectNotification,
  selectStatus,
} from '../../../slice';
import { RegisterOAuth2InitDto } from '../../../dto';
import { Button } from '../../../ui/Button';

export const RegisterInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: RegisterOAuth2InitDto) =>
    new Promise((resolve) => {
      dispatch(
        registerInitOpenId({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validator.validate(values, RegisterOAuth2InitDto, ['form'])
        }
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography color="text.light.tertirary">Привет!</Typography>
            <Box mt="20">
              <Typography color="text.light.tertirary">
                Для выпуска электронной подписи нужно подтвердить ваш актуальный
                номер мобильного телефона
              </Typography>
            </Box>
            <Box mt="20">
              <TextInput
                name="phone"
                label="Номер телефона"
                placeholder="+7"
                mask="+7 (999) 999-99-99"
                id="qaAuthRegisterInitPhoneInput"
              />
            </Box>
            <Box mt="32">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Далее
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
          </form>
        )}
      />
    </div>
  );
};

import { MutableState } from 'final-form';

export const setFieldData = <
  FormValues extends Record<string, any>,
  InitialFormValues = Partial<FormValues>,
>(
  [fieldName, fieldValue]: any[],
  state: MutableState<FormValues, InitialFormValues>,
) => {
  const field = state.fields[fieldName];

  if (field) {
    field.data = { ...field.data, ...fieldValue };
  }
};

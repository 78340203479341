import React from 'react';

import {
  Field,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { RadioInput as Input } from '../../input';

const handleChange =
  (
    input: FieldInputProps<string | undefined, HTMLElement>,
    onChange: (value: string | number) => void,
  ) =>
  (value: string | number) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

const RadioInput = ({
  label,
  tooltip,
  name,
  onChange,
  options,
  defaultValue,
  required,
  disabled,
  ...rest
}: {
  defaultValue?: string;
  options: { value: string | number; label: string; disabled?: boolean }[];
  after?: (value: string | undefined) => React.ReactNode;
} & FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <Field name={name} initialValue={defaultValue} {...rest}>
      {({ input }) => (
        <Input
          {...input}
          items={options}
          label={label}
          onChange={handleChange(input, onChange)}
          required={required}
          disabled={disabled}
          tooltip={tooltip}
        />
      )}
    </Field>
  );
};

export default RadioInput;

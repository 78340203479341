import React from 'react';

import MUIIconButton from '@material-ui/core/IconButton';
import {
  IconButtonClassKey,
  PropTypes,
  ButtonBaseActions,
} from '@material-ui/core';
import { TouchRippleProps } from '@material-ui/core/ButtonBase/TouchRipple';
import { ClassNameMap } from '@material-ui/styles';

export const IconButton = (
  props: JSX.IntrinsicAttributes & {
    color?: PropTypes.Color;
    disableFocusRipple?: boolean;
    edge?: false | 'end' | 'start';
    size?: 'small' | 'medium';
    component?: 'span';
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    type?: 'button';
    classes?: Partial<ClassNameMap<IconButtonClassKey>>;
  } & {
    action?: React.Ref<ButtonBaseActions>;
    buttonRef?: React.Ref<unknown>;
    centerRipple?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    disableRipple?: boolean;
    disableTouchRipple?: boolean;
    focusRipple?: boolean;
    focusVisibleClassName?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFocusVisible?: React.FocusEventHandler<any>;
    tabIndex?: string | number;
    TouchRippleProps?: Partial<TouchRippleProps>;
  },
) => {
  const { onClick, ...rest } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    onClick?.(e);
  };

  return (
    <MUIIconButton
      {...rest}
      onClick={handleClick}
      className={
        props.className
          ? 'aqa_icon_button'
          : `${props.className} aqa_icon_button`
      }
    >
      {props.children}
    </MUIIconButton>
  );
};

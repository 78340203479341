import { useContext } from 'react';

import { interfaces } from 'inversify';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';

import { Inject, IocContext } from '@vk-hr-tek/core/ioc';

import type { RootState } from '../store';

export const useSelector = <
  TSelected,
  TParam,
  TState extends RootState = RootState,
>(
  selector: (state: TState, inject: Inject, parameter?: TParam) => TSelected,
  parameter?: TParam,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => {
  const container = useContext(IocContext);

  if (!container) {
    throw new Error('Ioc container is empty');
  }

  const inject = <T>(identifier: interfaces.ServiceIdentifier<T>): T => {
    return container.get<T>(identifier);
  };

  const useAppSelector: TypedUseSelectorHook<TState> = useReduxSelector;

  return useAppSelector(
    (state) => selector(state, inject, parameter),
    equalityFn,
  );
};

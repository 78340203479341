import React, { useEffect, memo } from 'react';

import { Slide, Portal } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import {
  removeNotificationManually,
  selectNotifications,
  getNotifications,
} from '@vk-hr-tek/core/notifications';

import { useSelector, useDispatch } from '../../app/hooks';

import { NotificationContent } from './NotificationContent';
import { useStyles } from './Notifications.styles';

interface NotificationsProps {
  show?: boolean;
}

export const Notifications = memo(({ show = true }: NotificationsProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const notifications = useSelector(selectNotifications);

  const handleNotificationRemoval = (id: number) => () =>
    dispatch(removeNotificationManually(id));

  useEffect(() => {
    dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.notifications}>
        <div className={classes.stack}>
          {!!notifications &&
            !!notifications.length &&
            notifications.map(
              ({ id, message, type, title, action, subtitle }, index) => {
                return (
                  <Slide
                    direction="left"
                    // eslint-disable-next-line no-magic-numbers
                    timeout={500 + index * 200}
                    in
                    key={id}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Box mb="8">
                      <NotificationContent
                        text={message}
                        title={title}
                        subtitle={subtitle}
                        action={action}
                        handleClose={handleNotificationRemoval(id)}
                        icon={
                          type === 'error' ? (
                            <Cancel color="error" fontSize="medium" />
                          ) : (
                            <CheckCircle color="primary" fontSize="medium" />
                          )
                        }
                      />
                    </Box>
                  </Slide>
                );
              },
            )}
        </div>
      </div>
    </Portal>
  );
});

import { FORM_ERROR } from 'final-form';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { Crypto } from '@vk-hr-tek/core/crypto';
import { AppError } from '@vk-hr-tek/core/error';
import { showError } from '@vk-hr-tek/core/notifications';

import { ThunkExtra, ThunkValuesAndActions } from '../../../app/store';
import { PolicyDetailService } from '../../services';
import { AcceptDto, AcceptConfirmPoliciesDto } from '../../dto';

export const accept = createAsyncThunk<
  void,
  {
    values: AcceptDto;
    actions: {
      resolve: (value: Array<string>) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/accept',
  async (
    { values, actions },
    { dispatch, rejectWithValue, extra: { inject } },
  ) => {
    try {
      const result = await inject(PolicyDetailService).accept(values);

      actions.resolve(result.need_confirm);
    } catch (err) {
      dispatch(showError('Произошла ошибка, попробуйте еще раз'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const acceptCryptoLocal = createAsyncThunk<
  void,
  {
    values: {
      certificateId: string;
      policyId: string;
      policyVersionId: string;
      employeeId: string;
      hashSource?: 'api' | 'browser_plugin';
      tsp?: string;
    };
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/acceptCryptoLocal',
  async (
    { values, actions },
    { dispatch, rejectWithValue, extra: { inject } },
  ) => {
    try {
      const service = inject(PolicyDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);
      let hash = '';

      if (values.hashSource === 'browser_plugin') {
        const { file: blob } = await service.getDocument({
          policyId: values.policyId,
          policyVersionId: values.policyVersionId,
        });

        hash = await crypto.createHash(await blob);
      } else {
        const result = await service.getDocumentHash({
          policyId: values.policyId,
          policyVersionId: values.policyVersionId,
        });

        hash = result.hash;
      }

      const signature = await crypto
        .signHash(certificate, hash, values.tsp)
        .catch((err) => {
          throw new AppError('client', {
            code: 500,
            message: err?.message ?? 'Ошибка при подписании',
            data: {
              error_code: values?.tsp ? 'sign_hash_failed' : '',
            },
          });
        });

      await service.acceptCryptoLocal({
        policyVersions: [
          {
            policy_version_id: values.policyVersionId,
            employee_id: values.employeeId,
            signature,
          },
        ],
        certificateId: values.certificateId,
      });

      actions.resolve(null);
    } catch (err) {
      dispatch(showError('Произошла ошибка, попробуйте еще раз'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const acceptCryptoLocalBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<{
    certificateId: string;
    policies: {
      policyId: string;
      policyVersionId: string;
      employeeId: string;
      hashSource?: 'api' | 'browser_plugin';
      tsp?: string;
    }[];
  }>,
  ThunkExtra
>(
  'policy/signUnepLocalBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = await inject(PolicyDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);

      const signData = await Promise.all(
        values.policies.map(async (policy) => {
          let hash = '';

          if (policy.hashSource === 'browser_plugin') {
            const { file: blob } = await service.getDocument({
              policyId: policy.policyId,
              policyVersionId: policy.policyVersionId,
            });

            hash = await crypto.createHash(await blob);
          } else {
            const result = await service.getDocumentHash({
              policyId: policy.policyId,
              policyVersionId: policy.policyVersionId,
            });

            hash = result.hash;
          }

          return {
            policy,
            hash,
          };
        }),
      );

      const policyVersions = await Promise.all(
        signData.map(async ({ policy, hash }) => {
          return {
            policy_version_id: policy.policyVersionId,
            employee_id: policy.employeeId,
            signature: await crypto
              .signHash(certificate, hash, policy.tsp)
              .catch((err) => {
                throw new AppError('client', {
                  code: 500,
                  message: err?.message ?? 'Ошибка при подписании',
                  data: {
                    error_code: policy?.tsp ? 'sign_hash_failed' : '',
                  },
                });
              }),
          };
        }),
      );

      await service.acceptCryptoLocal({
        certificateId: values.certificateId,
        policyVersions,
      });

      actions.resolve(null);
    } catch (err) {
      dispatch(showError('Произошла ошибка, попробуйте еще раз'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const acceptCryptoUkep = createAsyncThunk<
  void,
  {
    values: {
      certificateId: string;
      policyId: string;
      policyVersionId: string;
      employeeId: string;
      hashSource?: 'api' | 'browser_plugin';
      tsp?: string;
    };
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/acceptCryptoUkep',
  async (
    { values, actions },
    { dispatch, rejectWithValue, extra: { inject } },
  ) => {
    try {
      const service = inject(PolicyDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);
      let hash = '';

      if (values.hashSource === 'browser_plugin') {
        const { file: blob } = await service.getDocument({
          policyId: values.policyId,
          policyVersionId: values.policyVersionId,
        });

        hash = await crypto.createHash(await blob);
      } else {
        const result = await service.getDocumentHash({
          policyId: values.policyId,
          policyVersionId: values.policyVersionId,
        });

        hash = result.hash;
      }

      const signature = await crypto
        .signHash(certificate, hash, values.tsp)
        .catch((err) => {
          throw new AppError('client', {
            code: 500,
            message: err?.message ?? 'Ошибка при подписании',
            data: {
              error_code: values?.tsp ? 'sign_hash_failed' : '',
            },
          });
        });

      await service.acceptCryptoUkep({
        policyVersions: [
          {
            policy_version_id: values.policyVersionId,
            employee_id: values.employeeId,
            signature,
          },
        ],
        certificateId: values.certificateId,
      });

      actions.resolve(null);
    } catch (err) {
      dispatch(showError('Произошла ошибка, попробуйте еще раз'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const acceptCryptoUkepBatch = createAsyncThunk<
  void,
  ThunkValuesAndActions<{
    certificateId: string;
    policies: {
      policyId: string;
      policyVersionId: string;
      employeeId: string;
      hashSource?: 'api' | 'browser_plugin';
      tsp?: string;
    }[];
  }>,
  ThunkExtra
>(
  'policy/acceptCryptoUkepBatch',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = await inject(PolicyDetailService);
      const crypto = await inject(Crypto);
      const certificate = await crypto.getSertificateById(values.certificateId);

      const signData = await Promise.all(
        values.policies.map(async (policy) => {
          let hash = '';

          if (policy.hashSource === 'browser_plugin') {
            const { file: blob } = await service.getDocument({
              policyId: policy.policyId,
              policyVersionId: policy.policyVersionId,
            });

            hash = await crypto.createHash(await blob);
          } else {
            const result = await service.getDocumentHash({
              policyId: policy.policyId,
              policyVersionId: policy.policyVersionId,
            });

            hash = result.hash;
          }

          return {
            policy,
            hash,
          };
        }),
      );

      const policyVersions = await Promise.all(
        signData.map(async ({ policy, hash }) => {
          return {
            policy_version_id: policy.policyVersionId,
            employee_id: policy.employeeId,
            signature: await crypto
              .signHash(certificate, hash, policy.tsp)
              .catch((err) => {
                throw new AppError('client', {
                  code: 500,
                  message: err?.message ?? 'Ошибка при подписании',
                  data: {
                    error_code: policy?.tsp ? 'sign_hash_failed' : '',
                  },
                });
              }),
          };
        }),
      );

      await service.acceptCryptoUkep({
        certificateId: values.certificateId,
        policyVersions,
      });

      actions.resolve(null);
    } catch (err) {
      dispatch(showError('Произошла ошибка, попробуйте еще раз'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const acceptConfirmPolicies = createAsyncThunk<
  void,
  {
    values: AcceptConfirmPoliciesDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/confirm_accept',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(PolicyDetailService).acceptConfirmPolicies(values);
      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

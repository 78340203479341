import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { Container } from '../../../../container';
import vkHrTekLogo from '../../../../../assets/vk-hr-tek-logo.svg';

interface PageErrorGeneralProps {
  error?: { title: string; description: string } | null;
}

export const PageErrorGeneral = ({ error }: PageErrorGeneralProps) => {
  const errorTitle = error ? error.title : 'Неизвестная ошибка';

  const errorText = error ? (
    <span dangerouslySetInnerHTML={{ __html: error.description }} />
  ) : (
    'Упс! Что-то пошло не так'
  );

  return (
    <Container
      theme="general"
      title={
        <Box>
          <img src={vkHrTekLogo} />
        </Box>
      }
    >
      <Box
        bgcolor="bg.light.primary"
        borderColor="stroke.primary"
        border={1}
        py={{ xs: '20', md: '48' }}
        px={{ xs: '20', md: '144' }}
        radius="l"
      >
        <Box mb="32" display="flex" justifyContent="center">
          <Typography variant="h5">{errorTitle}</Typography>
        </Box>

        <Box
          color="text.light.secondary"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Typography variant="body2">{errorText}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

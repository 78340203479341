import { IsNotEmpty, IsUUID, IsArray, IsString } from 'class-validator';

import { FormAttributesRequest } from '../../types';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class CompetencyEvalDto {
  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  eventId: string;

  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  nodeId: string;

  @IsString({ each: true })
  @IsArray()
  competencies: string[];

  attributes?: FormAttributesRequest;
}

import React, { ReactElement, useState, useEffect } from 'react';

import { useIntersectionObserver } from '@vk-hr-tek/core/hooks';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

import { FilePreviewContent } from './FilePreviewContent';

interface FilePreviewProps {
  onLoad: () => Promise<Blob>;
  shape?: 'auto' | 'square';
  onClickPrint?: () => void;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onError?: (e: Error | undefined) => void;
  onClickOpen?: () => void;
  onLoadSuccess?: () => void;
  isClickable?: boolean;
  withCollapsableFooter?: boolean;
  view?: 'file' | 'fileLarge' | 'link';
  showSnackbarOnDesktop?: boolean;
  snackbarMessage?: string;
  snackbarAction?: string;
  linkText?: string | ReactElement;
  hideLoader?: boolean;
  withFlex?: boolean;
  centerImage?: boolean;
}

export const FilePreview = (props: FilePreviewProps) => {
  const [isIntersected, setIsIntersected] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '100px',
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      setIsIntersected(true);
    }
  }, [entry?.isIntersecting]);

  return (
    <>
      <Box ref={ref} component="span" position="absolute" />
      {isIntersected ? (
        <FilePreviewContent {...props} />
      ) : (
        !props.hideLoader && (
          <Box display="flex" justifyContent="center" p="32">
            <CircularProgress size={50} />
          </Box>
        )
      )}
    </>
  );
};

import React, { useEffect } from 'react';

import classNames from 'classnames';
import { TreeView as MUITreeView } from '@material-ui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FiberManualRecord as BulletIcon,
} from '@material-ui/icons';

import { TreeItem } from './TreeItem';
import { Item } from './Item.interface';
import { useStyles } from './TreeView.styles';

interface BaseTreeViewProps {
  onSelect: (value: string, label?: string) => void;
  onChildrenLoad?: (unitId: string) => Promise<Item[]>;
  items: Item[];
  selectOnShrink?: boolean;
  showRulers?: boolean;
  showCounters?: boolean;
}

interface SingleSelectTreeViewProps extends BaseTreeViewProps {
  multiple?: false;
  selected: string;
}
interface MultiSelectTreeViewProps extends BaseTreeViewProps {
  multiple: true;
  selected: string[];
}

export type TreeViewProps =
  | SingleSelectTreeViewProps
  | MultiSelectTreeViewProps;

const parseParents: (
  items: Item[],
  parents?: string[],
) => Record<string, string[]> = (items, parents = []) => {
  let result: Record<string, string[]> = {};

  items.forEach((item) => {
    result[item.id] = parents;

    if (item.children && item.children.length) {
      result = {
        ...result,
        ...parseParents(item.children, [...parents, item.id]),
      };
    }
  });

  return result;
};

export const TreeView = (props: TreeViewProps) => {
  const classes = useStyles();

  const {
    onSelect,
    onChildrenLoad,
    items,
    selectOnShrink = true,
    showRulers = true,
    showCounters = true,
  } = props;

  const [expanded, expand] = React.useState<string[]>([]);
  const parentsOfSelected =
    (!props.multiple &&
      props.selected &&
      parseParents(items)[props.selected]) ||
    [];

  useEffect(() => {
    if (!props.multiple) {
      expand((previous) => [
        ...previous,
        ...(parseParents(items)[props.selected] || []),
      ]);
    }
  }, [props.selected, items, props.multiple]);

  return (
    <MUITreeView
      expanded={expanded}
      className={classNames(classes.root, 'aqa_tree_view')}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      defaultEndIcon={<BulletIcon className={classes.endIcon} />}
      {...(props.multiple
        ? {
            selected: props.selected,
            multiSelect: props.multiple,
          }
        : { selected: props.selected })}
    >
      {items.map((item) => (
        <TreeItem
          key={item.id}
          item={item}
          showRulers={showRulers}
          showCounters={showCounters}
          onSelect={onSelect}
          expanded={expanded}
          onExpand={(value) =>
            expand((previous) => {
              if (previous.includes(value)) {
                if (selectOnShrink && parentsOfSelected.includes(value)) {
                  onSelect(value);
                }
                return previous.filter(
                  (previousExpanded) => previousExpanded !== value,
                );
              }

              return [...previous, value];
            })
          }
          onChildrenLoad={onChildrenLoad}
          {...(props.multiple
            ? {
                selected: props.selected,
                multiple: props.multiple,
              }
            : { selected: props.selected })}
        />
      ))}
    </MUITreeView>
  );
};

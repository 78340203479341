import React from 'react';

export const RuStoreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68701 22.3129C3.37409 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.3129C24 20.6259 24 17.9106 24 12.48V11.52C24 6.08936 24 3.37408 22.313 1.68701C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37409 0 1.68701 1.68701C6.39578e-07 3.37408 0 6.08936 0 11.52V12.48C0 17.9106 6.39578e-07 20.6259 1.68701 22.3129Z"
      fill="#0077FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2952 16.1676L17.5852 15.7404C17.3793 15.6813 17.2339 15.496 17.2266 15.2807L17.0132 8.98721C16.9515 8.15424 16.3331 7.49169 15.687 7.29661C15.6507 7.28564 15.612 7.30041 15.5905 7.33161C15.5686 7.36338 15.5771 7.40728 15.6075 7.4308C15.7671 7.55437 16.2063 7.95744 16.2063 8.6527L16.205 16.8813C16.205 17.6758 15.4542 18.2586 14.6798 18.0651L12.9434 17.6314C12.7509 17.564 12.6171 17.3847 12.6101 17.1779L12.3967 10.8839C12.335 10.051 11.7167 9.38848 11.0705 9.19333C11.0342 9.18243 10.9955 9.19713 10.974 9.22833C10.9522 9.2601 10.9606 9.30399 10.991 9.32759C11.1506 9.45109 11.5898 9.85416 11.5898 10.5494L11.5885 17.4084L11.5895 17.4085V18.7788C11.5895 19.5734 10.8386 20.1562 10.0642 19.9627L5.15978 18.7375C4.47792 18.5672 3.99988 17.9573 3.99988 17.2577V9.01625C3.99988 8.22173 4.75068 7.639 5.5251 7.83243L8.61543 8.60443V7.11874C8.61543 6.32423 9.36623 5.74143 10.1407 5.93493L13.2308 6.70685V5.22117C13.2308 4.42665 13.9816 3.84385 14.7561 4.03735L19.6605 5.26248C20.3424 5.43281 20.8204 6.04272 20.8204 6.74229V14.9837C20.8204 15.7783 20.0696 16.361 19.2952 16.1676Z"
      fill="white"
    />
  </svg>
);

import React from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { selectUserRepresentative } from '../../user';
import { useSelector } from '../../app/hooks';

import { User as UserDesktop } from './desktop';
import { User as UserMobile } from './mobile';

export const User = () => {
  const isDesktop = useIsDesktop();
  const userRepresentative = useSelector(selectUserRepresentative);

  const isRepresentativeUser =
    window.REACT_APP_VKHRTEK_LK_DISABLED && userRepresentative;

  return isDesktop ? (
    <UserDesktop userRepresentative={isRepresentativeUser} />
  ) : (
    <UserMobile userRepresentative={isRepresentativeUser} />
  );
};

export interface PersonalRouter {
  goToList(query?: string): void;
  goToWorkBook(companyId: string): void;
  goToEducation(companyId: string): void;
  goToEmploymentContract(companyId: string): void;
  goToWorkHistory(companyId: string): void;
  goToAwardsPage(companyId: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalRouter = Symbol('PersonalRouter');

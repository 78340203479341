import React from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';
import { AppError } from '@vk-hr-tek/core/error';
import { ValidationService } from '@vk-hr-tek/core/validation';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';
import { Redo } from '@vk-hr-tek/ui/Redo';
import { Link } from '@vk-hr-tek/ui/Link';
import { Box } from '@vk-hr-tek/ui/Box';

import { useDispatch, useSelector } from '@app/hooks';

import { useAuth } from '../../../hooks';
import { LoginAliDto } from '../../../dto';
import {
  selectPhone,
  selectNotification,
  selectStatus,
  loginAli,
  sendAliPhoneCode,
  selectPassCode,
  changePhoneAliInit,
  redirectToQr,
  selectAuthInstance,
} from '../../../slice';
import { Page, PageError } from '../../../ui/page';
import { Button } from '../../../ui/Button';

export const Login = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const validator = useInject(ValidationService);

  const phone = useSelector(selectPhone);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const passCode = useSelector(selectPassCode);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor, button_text: buttonText } = authInstance;

  if (!isAuthenticated && !passCode) {
    return (
      <PageError
        error={
          new AppError('client', {
            code: 400,
            message: 'Bad request',
            data: {
              error_code: 'invalid_ali_link',
            },
          })
        }
      />
    );
  }

  const onSubmit = (values: LoginAliDto) =>
    new Promise((resolve) => {
      dispatch(
        loginAli({
          values,
          actions: { resolve },
        }),
      );
    });

  const resendCode = () => {
    return new Promise((resolve) => {
      dispatch(
        sendAliPhoneCode({
          actions: { resolve },
        }),
      );
    });
  };

  const changePhone = () => {
    return new Promise((resolve) => {
      dispatch(
        changePhoneAliInit({
          actions: { resolve },
        }),
      );
    });
  };

  return (
    <Page title="Вход">
      <Form
        onSubmit={onSubmit}
        validate={(values) => validator.validate(values, LoginAliDto, ['form'])}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue label="Номер телефона" value={phone} />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="code"
                label="СМС-код"
                placeholder="Введите СМС-код"
                type="number"
                inputMode="numeric"
                autocomplete="one-time-code"
                id="qaAuthLoginFinishCodeInput"
              />
            </Box>
            <Redo label="Отправить код повторно" action={resendCode} />
            <Box mt="24">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={submitting || pristine || hasValidationErrors}
              >
                {buttonText || 'Войти в HR Tek'}
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
            <Box
              mt="8"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link onClick={changePhone} size="small" variant="tertiary">
                Изменился телефон
              </Link>
              <Link
                onClick={() => dispatch(redirectToQr())}
                size="small"
                variant="tertiary"
              >
                Выход к QR-коду
              </Link>
            </Box>
          </form>
        )}
      />
    </Page>
  );
};

import {
  IsString,
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  Length,
  IsNumberString,
  IsUUID,
} from 'class-validator';

import { FormAttributesRequest } from '../../types';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

const SMS_CODE_LENGTH = 6;

export class SignUnepDto {
  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['code-verify'],
  })
  @IsString({
    groups: ['code-verify'],
  })
  @Length(SMS_CODE_LENGTH, SMS_CODE_LENGTH, {
    message: 'Неверный код',
    groups: ['code-verify'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный код',
      groups: ['code-verify'],
    },
  )
  code: string;

  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['form'],
  })
  @IsUUID(undefined, {
    groups: ['form'],
  })
  eventId: string;

  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['form'],
  })
  @IsUUID(undefined, {
    groups: ['form'],
  })
  nodeId: string;

  @IsBoolean()
  @IsOptional()
  skipSms?: boolean;

  attributes?: FormAttributesRequest;
}

import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  createAction,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '../../error';
import {
  GroupingStrategy,
  NotificationData,
  Notification,
  NotificationsMapper,
  NotificationsEventEmitter,
} from '../types';
import { Notification as NotificationPayload } from '../gen/notifications';
import { Socket } from '../../websocket';
import { ThunkExtra } from '../../utils';

import { NotificationsState } from './notifications.state';

interface NotificationStateInterface {
  notifications: NotificationsState;
}

const POLLING_TIMEOUT = 15000;
//eslint-disable-next-line no-magic-numbers
const CHECK_QUEUE_TIMEOUT = POLLING_TIMEOUT + 500;

export const setQueue = createAction<Notification[]>(
  'core/notifications/setQueue',
);

export const addNotificationToQueue = createAction<Notification>(
  'core/notifications/addNotificationToQueue',
);

export const addNotificationToList = createAction<Notification>(
  'core/notifications/addNotificationToList',
);

export const removeNotification = createAction<number>(
  'core/notifications/removeNotification',
);

export const resetNotifications = createAction(
  'core/notifications/setNotifications',
);

export const checkQueue = createAsyncThunk<
  void,
  undefined,
  ThunkExtra<NotificationStateInterface>
>(
  'core/notifications/checkQueue',
  (_, { dispatch, getState, extra: { inject } }) => {
    const { queue, notifications } = getState().notifications;

    const groupingStrategy = inject<GroupingStrategy>(GroupingStrategy);
    const eventEmitter = inject<NotificationsEventEmitter>(
      NotificationsEventEmitter,
    );

    if (!queue.length) {
      return;
    }

    const groupedNotification = groupingStrategy.groupNotifications(queue);

    const mql = window.matchMedia(`(min-width: 1024px)`);
    //eslint-disable-next-line no-magic-numbers
    const maxCount = mql.matches ? 4 : 2;
    const count = maxCount - notifications.length;

    const toAddToList = groupedNotification.slice(0, count);

    toAddToList.forEach((notification) => {
      dispatch(addNotificationToList(notification));
      eventEmitter.onShow(notification);

      setTimeout(() => {
        dispatch(removeNotification(notification.id));
      }, POLLING_TIMEOUT);
      setTimeout(() => {
        dispatch(checkQueue());
      }, CHECK_QUEUE_TIMEOUT);
    });

    dispatch(setQueue(groupedNotification.slice(count)));
  },
);

export const showError = createAsyncThunk<
  void,
  string,
  ThunkExtra<NotificationStateInterface>
>('core/notifications/showError', async (message, { dispatch }) => {
  //eslint-disable-next-line no-magic-numbers
  const id = Math.floor(Math.random() * 5000);

  dispatch(
    addNotificationToQueue({
      id,
      message,
      type: 'error',
    }),
  );

  dispatch(checkQueue());
});

export const showNotification = createAsyncThunk<
  void,
  string,
  ThunkExtra<NotificationStateInterface>
>('core/notifications/showNotification', async (message, { dispatch }) => {
  //eslint-disable-next-line no-magic-numbers
  const id = Math.floor(Math.random() * 5000);

  dispatch(
    addNotificationToQueue({
      id,
      message,
      type: 'info',
    }),
  );

  dispatch(checkQueue());
});

export const showCustomNotification = createAsyncThunk<
  void,
  NotificationData[],
  ThunkExtra<NotificationStateInterface>
>('core/notifications/showNotification', async (data, { dispatch }) => {
  data.forEach((item) => {
    //eslint-disable-next-line no-magic-numbers
    const id = Math.floor(Math.random() * 5000);

    dispatch(
      addNotificationToQueue({
        id,
        ...item,
      }),
    );
  });

  dispatch(checkQueue());
});

export const removeNotificationManually = createAsyncThunk<
  void,
  number,
  ThunkExtra<NotificationStateInterface>
>('core/notifications/removeNotificationManually', async (id, { dispatch }) => {
  dispatch(removeNotification(id));
  dispatch(checkQueue());
});

export const notificationListener = createAsyncThunk<
  void,
  NotificationPayload,
  ThunkExtra<NotificationStateInterface>
>(
  'core/notifications/notificationListener',
  async (payload, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const notificationsMapper =
        inject<NotificationsMapper>(NotificationsMapper);

      const result = notificationsMapper.mapNotifications([payload]);

      if (result.length) {
        dispatch(showCustomNotification(result));
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getNotifications = createAsyncThunk<
  void,
  void,
  ThunkExtra<NotificationStateInterface>
>(
  'core/notifications/getNotifications',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const socket = inject(Socket);

      socket.on('notification', (payload: NotificationPayload) =>
        dispatch(notificationListener(payload)),
      );

      socket.connect();
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const notificationsReducers = (
  builder: ActionReducerMapBuilder<NotificationsState>,
) => {
  builder.addCase(setQueue, (state, action) => {
    state.queue = action.payload;
  });
  builder.addCase(addNotificationToQueue, (state, action) => {
    state.queue.push(action.payload);
  });
  builder.addCase(addNotificationToList, (state, action) => {
    state.notifications.push(action.payload);
  });
  builder.addCase(removeNotification, (state, action) => {
    state.notifications = state.notifications.filter(
      ({ id }) => id !== action.payload,
    );
  });
  builder.addCase(resetNotifications, (state) => {
    state.notifications = [];
    state.queue = [];
  });
};

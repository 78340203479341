import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { HTTP_BAD_REQUEST_STATUS } from '@vk-hr-tek/core/http/codes';

import { AuthInstanceResponse } from '@app/gen/users';
import { ThunkExtra } from '@app/store';

import { AuthService } from '../../services';
import { AuthState } from '../auth.state';

export const getAuthInstance = createAsyncThunk<
  AuthInstanceResponse,
  undefined,
  ThunkExtra
>('auth/getAuthInstance', async (_, { rejectWithValue, extra: { inject } }) => {
  try {
    const authService = inject(AuthService);

    const result = await authService.getAuthInstance();

    return result;
  } catch (err) {
    return rejectWithValue(classToPlain(err) as AppError);
  }
});

export const authReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(getAuthInstance.pending, (state) => {
    state.authInstances.status = 'loading';
    state.authInstances.instances = [];
  });
  builder.addCase(getAuthInstance.fulfilled, (state, { payload }) => {
    state.authInstances.status = 'complete';
    state.authInstances.instances = payload.auth_instances;
  });
  builder.addCase(getAuthInstance.rejected, (state, { payload, error }) => {
    state.authInstances.status =
      payload?.status === HTTP_BAD_REQUEST_STATUS ? 'loading' : 'failed';
    state.authInstances.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};

import { Type } from 'class-transformer';
import {
  IsIn,
  IsNotEmpty,
  IsString,
  IsUUID,
  IsOptional,
  IsInt,
  Min,
} from 'class-validator';

export class GetAbsenceListDto {
  @IsInt()
  @Min(0)
  @Type(() => Number)
  @IsOptional()
  offset = 0;

  @IsInt()
  @Min(1)
  @Type(() => Number)
  @IsOptional()
  // eslint-disable-next-line no-magic-numbers
  limit = 20;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsIn(['month', 'year'])
  @IsString()
  @IsNotEmpty()
  calendarType? = 'month';

  @IsString()
  @IsOptional()
  month?: number;

  @IsString()
  @IsOptional()
  year?: number;
}

export class GetAbsenceListServiceDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsString()
  @IsNotEmpty()
  year: number;
}

import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsOptional,
  IsUUID,
  ValidateNested,
  IsArray,
  IsString,
  IsIn,
} from 'class-validator';

class ManagerSettings {
  @IsUUID(undefined, {
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  id: string;

  @IsIn(['always', 'direct_only', 'never'], {
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsString({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  value: 'always' | 'direct_only' | 'never';
}

export class UpdateCompanyUnitDto {
  @IsUUID(undefined, {
    groups: ['submit'],
  })
  @IsNotEmpty({
    groups: ['submit'],
  })
  companyId: string;

  @IsUUID(undefined, {
    groups: ['submit'],
  })
  @IsNotEmpty({
    groups: ['submit'],
  })
  unitId: string;

  @IsString({
    message: 'Вы указали неверное название подразделения',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать название подразделения',
    groups: ['form', 'root', 'submit'],
  })
  name: string;

  @IsOptional({
    groups: ['submit'],
  })
  @IsUUID(undefined, {
    message: 'Вы указали неверное родительское подразделение',
    groups: ['submit'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать родительское подразделение',
    groups: ['form'],
  })
  parentId: string;

  @IsUUID(undefined, {
    message: 'Вы указали неверного руководителя подразделения',
    groups: ['submit'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать руководителя подразделения',
    groups: ['submit'],
  })
  managerId: string;

  @ValidateNested({ each: true })
  @Type(() => ManagerSettings)
  @IsArray({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  managerSettings: ManagerSettings[];
}

import { t } from '@vk-hr-tek/core/translations/t';

import { UserRoleEnum, UserRoles } from './types';

export const ATTORNEYS_POWERS_DEFAULT = 'MINTRUD_MIN01';

export const ROLES = (): UserRoles[] => {
  return [
    {
      role: UserRoleEnum.Company,
      title: 'Рабочий функционал',
    },
    {
      role: UserRoleEnum.Employee,
      title: t('dashboard.constants.employeeServices'),
    },
  ];
};

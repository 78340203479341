import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DocumentCopyIcon = ({
  size = 'normal',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <SvgIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6668 0.666748H2.66683C1.9335 0.666748 1.3335 1.26675 1.3335 2.00008V11.3334H2.66683V2.00008H10.6668V0.666748ZM12.6668 3.33341H5.3335C4.60016 3.33341 4.00016 3.93341 4.00016 4.66675V14.0001C4.00016 14.7334 4.60016 15.3334 5.3335 15.3334H12.6668C13.4002 15.3334 14.0002 14.7334 14.0002 14.0001V4.66675C14.0002 3.93341 13.4002 3.33341 12.6668 3.33341ZM12.6668 14.0001H5.3335V4.66675H12.6668V14.0001Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
    </SvgIcon>
  );
};

import React from 'react';

import { Router } from 'react-router-dom';

import { history } from '@vk-hr-tek/core/history';

import { init } from '@app/init';
import { metaTitles } from '@app/router';
import { InjectReducerContextProvider } from '@app/context';

import {
  GeneralLayout,
  CandidateLayout,
  ThemeProvider,
  AbilityProvider,
  TranslationsProvider,
} from './layout';
import { InjectReducer } from './app/types';
import { useApplication } from './auth/hooks/useApplication';
import CandidateApp from './CanidateApp';
import MainApp from './MainApp';

init({ history });

interface AppProps {
  injectReducer: InjectReducer;
}

function App({ injectReducer }: AppProps) {
  const appType = useApplication();

  return (
    <TranslationsProvider>
      <AbilityProvider role="employee">
        <InjectReducerContextProvider injectReducer={injectReducer}>
          <ThemeProvider>
            <Router history={history}>
              {appType === 'candidateApp' ? (
                <CandidateLayout>
                  <CandidateApp />
                </CandidateLayout>
              ) : (
                <GeneralLayout metaTitles={metaTitles}>
                  <MainApp />
                </GeneralLayout>
              )}
            </Router>
          </ThemeProvider>
        </InjectReducerContextProvider>
      </AbilityProvider>
    </TranslationsProvider>
  );
}

export default App;

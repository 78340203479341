import React from 'react';

import {
  useField,
  FieldProps,
  FieldRenderProps,
  FormSpy,
  useForm,
} from 'react-final-form';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { DateInput as Input } from '../../input';

export const DateInput = ({
  label,
  name,
  tooltip,
  onClear,
  placeholder = 'ДД.ММ.ГГГГ',
  required = false,
  disablePast = false,
  minDate = Calendar.minDate,
  maxDate = Calendar.maxDate,
  recognizedValue,
  shouldDisableDate,
  externalError,
  otherSelectedDate,
  clearable = false,
  disabled = false,
  showRange = false,
  alwaysShowClear = false,
  loading = false,
  isRecognitionUsedBefore = false,
  testId,
  validateArray,
  warnings,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined> & {
    validateArray?: ValidateType[] | undefined;
    warnings?: ValidateType[] | undefined;
  }
>) => {
  const { mutators } = useForm();

  const { input, meta } = useField(name, {
    validate: compose(validateArray || []),
    ...rest,
  });

  const composedWarnings = compose(warnings || []);

  return (
    <>
      <Input
        {...input}
        testId={testId}
        label={label}
        tooltip={tooltip}
        onClear={onClear}
        disabled={disabled}
        placeholder={placeholder}
        error={!disabled && meta.touched && meta.error}
        warning={!disabled && meta.touched && meta.data?.warning}
        minDate={minDate}
        maxDate={maxDate}
        recognizedValue={recognizedValue}
        isRecognitionUsedBefore={isRecognitionUsedBefore}
        required={required}
        disablePast={disablePast}
        otherSelectedDate={otherSelectedDate}
        externalError={externalError}
        shouldDisableDate={shouldDisableDate}
        clearable={clearable}
        showRange={showRange}
        alwaysShowClear={alwaysShowClear}
        loading={loading}
      />
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          mutators.setFieldData(input.name, {
            warning: composedWarnings(values[input.name], values),
          });
        }}
      />
    </>
  );
};

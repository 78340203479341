/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { ClassNameMap } from '@material-ui/styles';
import { Fade } from '@material-ui/core';

import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { useTheme } from '@vk-hr-tek/ui/Theme';

import { useSelector, useRole } from '@app/hooks';

import { LayoutService } from '../../services';
import { selectSidebarState } from '../../slice';
import { TIMEOUT_FOR_FADE } from '../Sidebar';

import { LogoEmptyIcon } from './LogoEmptyIcon';
import { useStyles } from './Logo.styles';
import { LogoEmptyIconText } from './LogoEmptyIconText';

type LogoIconVariants = '24' | '32' | '72' | '80';

interface LogoProps {
  variant?: LogoIconVariants;
  inSidebar?: boolean;
}

const getSizeClass = (variant: LogoIconVariants, classes: ClassNameMap) => {
  switch (variant) {
    case '24':
      return classes.icon24;
    case '72':
      return classes.icon72;
    case '80':
      return classes.icon80;
    default:
      return classes.icon32;
  }
};

export const Logo = ({ variant = '32', inSidebar = false }: LogoProps) => {
  const classes = useStyles();
  const [role] = useRole();
  const layout = useInject(LayoutService);
  const theme = useTheme();

  const sidebarState = useSelector(selectSidebarState);

  const [error, setError] = useState<Error>();

  const sizeClass = getSizeClass(variant, classes);

  const loadDocument = useCallback(async () => {
    const res = await layout.getLogo();

    return res.file;
  }, [role]);

  useEffect(() => {
    setError(undefined);
  }, [role]);

  if (error) {
    if (inSidebar) {
      return (
        <Box className={classes.sidebarErrorLogo}>
          <LogoEmptyIcon variant={variant} />

          {theme !== 'otp' && (
            <Fade in={sidebarState} timeout={TIMEOUT_FOR_FADE}>
              <Box display="flex" alignItems="center">
                <LogoEmptyIconText />
              </Box>
            </Fade>
          )}
        </Box>
      );
    } else {
      return <LogoEmptyIcon variant={variant} />;
    }
  }

  return (
    <div className={inSidebar ? classes.sidebarLogo : sizeClass}>
      <FilePreview onLoad={loadDocument} hideLoader onError={setError} />
    </div>
  );
};
